.breadCrum {
  display: flex;
  padding: 5px;
  padding-left: 0px;
  height: 46px;
  & > * {
    font-size: 12px;
    padding: 8px;
    color: $textColor2;
    text-decoration: none !important;
    &:last-child:after { content: none; }
    &:after { content: ">"; margin-left: 15px; }
  }
}