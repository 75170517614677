.lazyframe {
  position: relative;
  margin-bottom: 100px;

  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 315px;
  width: 100%;

  &__title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 15px 17px;
    z-index: 3;

    &:after {
      z-index: -1;
    }
  }

  &[data-vendor='youtube'] {
    background-color: lightgray;
    font-family: Roboto, Arial, Helvetica, sans-serif;

    .lazyframe__title {
      color: rgb(238, 238, 238);
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-size: 18px;
      text-shadow: rgba(0, 0, 0, 0.498039) 0px 0px 2px;
      -webkit-font-smoothing: antialiased;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);

      &:hover {
        color: #fff;
      }

      &:before {
        content: '';
        display: block;
        background: linear-gradient(rgba(0, 0, 0, 0.2), transparent);
        height: 98px;
        width: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }

    &:before {
      padding-top: 56.25%;
    }
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 68px;
      height: 48px;
      margin-left: -34px;
      margin-top: -24px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjU1LjcgNDQ2LjNjLTUzLjMuMy0xMDYuNi0uNC0xNTkuOC0zLjMtMTcuNC0xLTM0LjctMi41LTUwLjQtMTFDMzUgNDI2LjMgMjcgNDE4LjQgMjIgNDA3LjIgMTMuMiAzODguNiAxMC41IDM2OSA5IDM0OWMtMy40LTQxLjMtMy42LTgyLjYtMS44LTEyMy44IDEtMjIgMS42LTQ0IDYuOC02NS41IDItOC40IDUtMTYuNiA4LjgtMjQuNEMzMiAxMTcgNDggMTA4IDY3LjMgMTA0YzE2LjItMyAzMi44LTMgNDkuMy0zLjcgNTYtMi4zIDExMi0zLjUgMTY4LTMgNDMgLjYgODYuMiAxLjcgMTI5LjMgNCAxMy4yLjYgMjYuNi44IDM5LjMgNS41IDE3LjIgNi40IDMwIDE3LjIgMzcgMzQuNyA2LjYgMTYuOCA5LjIgMzQuMiAxMC42IDUyIDMuOCA0OC43IDQgOTcuMy43IDE0Ni0xIDE2LjMtMi4yIDMyLjctNi41IDQ4LjgtOS43IDM3LTMyLjggNTEuNS02Ni43IDUzLjgtMzYuMiAyLjUtNzIuNSAzLjgtMTA4LjggNC4zLTIxLjMuMi00Mi43IDAtNjQgMHpNMjAzLjIgMzQ0TDM0OCAyNjQuN2wtMTQ0LjgtNzkuM1YzNDR6IiBmaWxsPSIjIzFmMWYxZiIvPjxwYXRoIGQ9Ik0yMDMuMiAzNDRWMTg1LjVMMzQ4IDI2NC44IDIwMy4yIDM0NHoiIGZpbGw9IiNGRUZERkQiLz48L3N2Zz4=');
      background-position: center center;
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 0.81;

      border: none;
      z-index: 4;
    }

    &:hover:after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjU1LjcgNDQ2LjNjLTUzLjMuMy0xMDYuNi0uNC0xNTkuOC0zLjMtMTcuNC0xLTM0LjctMi41LTUwLjQtMTFDMzUgNDI2LjMgMjcgNDE4LjQgMjIgNDA3LjIgMTMuMiAzODguNiAxMC41IDM2OSA5IDM0OWMtMy40LTQxLjMtMy42LTgyLjYtMS44LTEyMy44IDEtMjIgMS42LTQ0IDYuOC02NS41IDItOC40IDUtMTYuNiA4LjgtMjQuNEMzMiAxMTcgNDggMTA4IDY3LjMgMTA0YzE2LjItMyAzMi44LTMgNDkuMy0zLjcgNTYtMi4zIDExMi0zLjUgMTY4LTMgNDMgLjYgODYuMiAxLjcgMTI5LjMgNCAxMy4yLjYgMjYuNi44IDM5LjMgNS41IDE3LjIgNi40IDMwIDE3LjIgMzcgMzQuNyA2LjYgMTYuOCA5LjIgMzQuMiAxMC42IDUyIDMuOCA0OC43IDQgOTcuMy43IDE0Ni0xIDE2LjMtMi4yIDMyLjctNi41IDQ4LjgtOS43IDM3LTMyLjggNTEuNS02Ni43IDUzLjgtMzYuMiAyLjUtNzIuNSAzLjgtMTA4LjggNC4zLTIxLjMuMi00Mi43IDAtNjQgMHpNMjAzLjIgMzQ0TDM0OCAyNjQuN2wtMTQ0LjgtNzkuM1YzNDR6IiBmaWxsPSIjREQyQzI4Ii8+PHBhdGggZD0iTTIwMy4yIDM0NFYxODUuNUwzNDggMjY0LjggMjAzLjIgMzQ0eiIgZmlsbD0iI0ZFRkRGRCIvPjwvc3ZnPg==');
      opacity: 1;
    }
  }

  &[data-vendor='vimeo'] {
    background-color: #00adef;

    .lazyframe__title {
      font-family: 'Helvetica Neue', Helvetica, Arial;
      color: #00adef;
      font-size: 20px;
      font-weight: bold;
      text-rendering: optimizeLegibility;
      user-select: none;
      -webkit-font-smoothing: auto;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      background-color: rgba(0, 0, 0, 0.5);
    }

    &:after {
      content: '';
      height: 40px;
      width: 65px;
      display: block;
      position: absolute;
      bottom: 10px;
      left: 10px;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.5);
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgYmFzZVByb2ZpbGU9InRpbnkiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTcuNzY1IDE2Ljg5bDguNDctNC44OS04LjQ3LTQuODkiLz48L3N2Zz4=');
      background-position: center center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 5px;
      position: relative;
    }
    &:hover:after {
      background-color: #00adef; //rgba(0,0,0,.75);
    }

    &:before {
      padding-top: 56.25%;
    }
  }

  &[data-vendor='vine'] {
    background-color: #00bf8f;
    &:before {
      padding-top: 100%;
    }

    .lazyframe__title {
      color: #fff;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;
      z-index: 3;
      positon: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        height: 115px;
        padding: 24px 70px 24px 24px;
        background: linear-gradient(to top, rgba(23, 23, 23, 0) 0, rgba(23, 23, 23, 0.7) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#b3171717", endColorstr="#00171717", GradientType=0 );
      }
    }
    &:after {
      content: '';
      width: 60px;
      height: 60px;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 4;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMzYiIGhlaWdodD0iMTM2IiB2aWV3Qm94PSIwIDAgMTM2IDEzNiI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTU2IDQ0Yy0uNyAwLTEuNC4yLTIgLjUtMS4yLjgtMiAyLTIgMy41djQwYzAgMS40LjggMi44IDIgMy41LjYuMyAxLjMuNSAyIC41czEuNC0uMiAyLS41bDM0LjYtMjBjMS4zLS43IDItMiAyLTMuNSAwLTEuNC0uNy0yLjgtMi0zLjVMNTggNDQuNWMtLjYtLjMtMS4zLS41LTItLjV6Ii8+PC9zdmc+');
      background-color: rgba(0, 0, 0, 0.5);
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: -30px;
      margin-left: -30px;
      border-radius: 50%;
    }
    &:hover:after {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }

  &:hover {
    cursor: pointer;
  }
  // Ratios
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 100%;
  }

  &[data-ratio='1:1']:before {
    padding-top: 100%;
  }
  &[data-ratio='4:3']:before {
    padding-top: 75%;
  }
  &[data-ratio='16:9']:before {
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
  }
}
