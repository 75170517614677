.Hides {
  .background-stripped {
    background-color: grey;
    border-radius: 24px;
    padding: 30px 15px;
    position: relative;
    &:before {
      border-radius: 16px;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px; top: 0px;
      overflow: hidden;
      background: repeating-linear-gradient(45deg, #F8F8F8, #F8F8F8 40px, #F2F2F2 40px, #F2F2F2 80px);
    }
  }
}
