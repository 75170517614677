.SearchResults {
  .cardBucket {
    min-height: 100vh;
  }
  .moreViews {
    cursor: pointer;
    text-align: center;
    margin-top: -30px;
    z-index: 9;
    position: absolute;
    right: 0px;
    left: 0px;
    &:hover {
      color: $primaryColor;
    }
  }
  .sticky4RadiusTab {
    padding-top: 20px;
    margin-top: 177px;
    z-index: 8;
  }
  .image4Radius {
    width: calc(100%);
    height: 20px;
    position: absolute;
    margin-top: -20px;
    top: 36px;
    &:before {
      content: '';
      background-image: url('/images/curva.png');
      width: 14px;
      height: 14px;
      z-index: 1;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:after {
      content: '';
      background-image: url('/images/curva.png');
      width: 14px;
      height: 14px;
      z-index: 1;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      right: 0px;
      transform: rotate(90deg);
    }
  }
  .scrollContainer {
    @media screen and (min-width: 768px) {
      padding-right: 30px;
      margin-right: -15px;
      .ps__rail-y {
        z-index: 1;
        right: 7px !important;
      }
      .cardBucket {
        margin-right: 0px;
        margin-left: 15px;
        & > .col-xl-6:nth-child(odd) {
          padding-left: 0px !important;
        }
        & > .col-xl-6:nth-child(even) {
          padding-right: 0px !important;
        }
        & > .col-xl-12 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
    }

    &:hover {
      .ps__rail-y {
        opacity: 0.2;
      }
    }

    .ps__rail-y {
      right: 10px !important;
      &:hover {
        .ps__thumb-y {
          right: -1px !important;
        }
      }
      .ps__thumb-y {
        transition: all 0.2s ease;
        right: 2px !important;
      }
    }

    height: 90%;
  }

  .mobile-map-container {
    .btn-mapa {
      position: fixed;
      bottom: 10px;
      left: 0px;
      right: 0px;
      width: 180px;
      margin: auto;
      font-size: 16px;
      line-height: 16px;
      z-index: 9;
    }
    .MapContainer {
      transition: all 0.3s ease;
      position: fixed;
      width: 100%;
      height: 100% !important;
      left: 0px;
      top: 150%;
      z-index: 7;
    }
  }
  .subFilters {
    .layoutButtons {
      width: 90px;
      flex-grow: initial;
      margin-left: 56px;
    }
    .orderByText {
      padding: 0px !important;
      height: 24px;
      display: flex;
      align-items: center;
      margin-right: 16px;
    }
    .customListFilter {
      & > div {
        margin-right: 12px;
      }
      & > div:nth-child(3) {
        margin-right: 0px;
      }
      .list-group-item {
        padding: 4px !important;
      }
      .active {
        color: $primaryColor;
      }
    }
    &.like-btns {
      .customListFilter {
        & > div {
          border: 1px solid #c5c5c5;
          border-radius: 6px;
          margin: 0px 5px;
          &.active {
            border-color: $primaryColor;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .customListFilter {
        .list-group-item {
          border: 1px solid $borderInputColor;
          border-radius: 8px;
          padding: 9px 10px !important;
          position: relative;
          height: 32px;
          &.active {
            border: 1px solid $primaryColor;
          }
        }
      }
    }
  }

  .background-results {
    padding-top: 80px !important;
    border: 0px solid rgba(255, 255, 255, 1);
    display: flex;
    padding: 16px 0px;
    width: 100%;
    border-radius: 24px;
    background-color: #f8f8f8;
    display: flex;
  }

  .SearchResults [class^='col-'] {
    transition: all 0.2s ease;
  }

  .stickyParent {
    & > div {
      width: 100%;
    }
    .isSticky {
      margin-top: 60px;
    }
  }

  .scrollThis {
    max-height: 430px;
  }

  .noShow {
    opacity: 0;
  }

  @keyframes shownFilterTab {
    0% {
      padding-top: 0px;
    }
    100% {
      padding-top: 80px;
    }
  }

  .stickyFilterTab {
    margin-top: 70px;
    z-index: 8;
    background: #f8f8f8;
  }

  @keyframes shownMapTab {
    0% {
      padding-top: 0px;
    }
    100% {
      padding-top: 200px;
    }
  }

  .helperStickyRadius {
    & > div:nth-child(1) {
      min-height: 0px !important;
      height: 0px !important;
    }
  }
  .stickyMapTab {
    padding-top: 190px;
  }

  .stickyNavBarTab {
    padding-top: 80px !important;
    z-index: 1;
    background: #f8f8f8;
  }

  @media screen and (min-width: 768px) {
    .background-results {
      padding: 16px;
      min-height: 950px;
      .fixedSize-16 {
        padding-left: 16px;
        padding-right: 16px;
      }
      .fixedSize-24 {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
  .SearchResults {
    @media screen and (max-width: 768px) {
      .background-results {
        padding-top: 100px !important;
      }
    }
  }

  .mobile-map-container {
    z-index: 7;
    &:before {
      content: '';
      bottom: 0px;
      position: fixed;
      width: 100%;
      height: 0px;
      left: 0px;
      box-shadow: 0px 0px 50px 50px white;
    }
    .zoom {
      margin-bottom: 120px;
    }
    &.active {
      .MapContainer {
        top: calc(0% + 70px);
      }
    }
  }

  .pushedMap {
    transition: all 0.12s ease 0s !important;
    position: relative;
    height: calc(100vh - 198px) !important;
    border-radius: 16px;
    overflow: hidden;

    .MapContainer {
      .zoom {
        margin-bottom: 32px;
      }
    }
  }

  @media screen and (max-width: 1020px) {
    .layoutButtons {
      .customListGroup {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .cardbox {
      box-shadow: 0px 0px 8px #6c757d0f !important;
    }
  }

  .miniCardBox {
    z-index: 999;
    position: absolute;
    width: 310px !important;
    left: 50%;
    transform: translateX(-50%) scale(.8);
    margin-top: 5px;
    min-height: 153px !important;
    .results-gallery {
      .slick-list {
        max-height: 167px;
      }
    }
  }
  .results-gallery {
    .slick-list {
      max-height: 209px;
    }
    .sliderImage {
      max-width: 100%;
    }
  }
  .gallery-item-main {
    padding: 10px;
    height: calc(100vh - 70px - 96px - 40px);
    @media screen and (max-width: 768px) {
      height: calc(100vh - 70px - 80px);
    }
  }

  .sliderImage {
    max-width: 100%;
  }

  .results-subtitle-row {
    padding: 0 1rem !important;
    align-items: center !important;
    justify-content: space-around !important;
    justify-content: center;
    display: flex !important;
    column-gap: 12px;
    outline: none !important;
    box-sizing: border-box;
    font-size: 14px !important;
    flex-wrap: nowrap !important;
    width: 100% !important;

    @media screen and (min-width: 1650px) {
      padding: 0 calc(4vw - 0px) !important;
    };
    @media screen and (max-width: 599px) {
      margin-top: 50px !important;
    }
  }

  .results-subtitle-col {
    align-items: center!important;
    box-sizing: border-box;
    display: flex !important;
    height: fit-content;
    max-height: fit-content;
    min-width: max-content;
    max-width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    outline: none !important;
    text-align: center !important;
    width: 100%;
  }

  .results-count-subtitle {
    line-height: 20px !important;
    margin: 0 !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #9B9B9B !important;
    outline: none !important;
    box-sizing: border-box;
    p {
      color: #9B9B9B !important;
      margin: 0px !important;
    }
  }

  .results-order-subtitle {
    align-items: center;
    color: #6C757D !important;
    font-size: 14px !important;
    padding: 0px !important;
    height: fit-content;
    line-height: 20px !important;
    font-weight: 700 !important;
    display: flex;
    margin-bottom: 0 !important;
    margin-right: 8px;
    margin-top: 0;
    min-width: max-content;
    outline: none !important;
    font-size: 12px;
    text-align: center !important;
    width: max-content;
  }
}
