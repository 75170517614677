.labelBucket {
  padding: 8px;
  font-size: 12px; line-height: 12px;
  color: $textColor2;
  background-color: $greyBg;
  border-radius: 4px;
  display: inline-block;
  align-items: center;
  &.blue {
    background-color: $primaryColor;
    color: white;
  }
  &.lightGreen {
    background-color: #E2F9F6;
    color: #21CFBC;
  }
  &.lightBlue {
    background-color: #D9E6FE;
    color: #0055FB;
  }
  &.orange {
    background-color: #FFF7E9;
    color: #FEAD29;
  }
}