.ReadMoreText {
  &.more {
    p:first-child {
      &:after { 
        box-shadow: 0px 0px 13px 10px transparent !important;
      }
    }
  }
  &.with-shadow {
    p:first-child:after { 
      box-shadow: 0px 0px 13px 10px #fffffffa;
    }
  }
  p:first-child {
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease;
    &:after { 
      transition: all 0.2s ease;
      content: "";
      position: absolute;
      left: 0px;
      width: 100%;
      opacity: 1;
      z-index: 999;
      height: 0px;
      bottom: 0px;
      
    }
  }
  .morebtn { color: $primaryColor; cursor: pointer; }
}