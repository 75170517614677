@keyframes shown_modal {
  0% {
    top: 0;
    opacity: 0;
  }
  10% {
    top: 0;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.Modal {
  position: fixed;
  z-index: 100;
  top: 100vh; //top: 0px;
  opacity: 0;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  &:before {
    position: absolute;
    content: "";
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background: #0000008f;
  }
  &.active {
    animation: shown_modal 0.3s ease forwards;
    .content {
      top: 0px;
    }
  }
  & > .content {
    z-index: 99;
    transition: top 0.3s 0.2s;
    top: 100vh;
    position: relative;
    width: 500px;
    max-width: 90vw;
    background: white;
    border-radius: 24px;
    margin: auto;
    .body {
      padding: 30px;
    }
    .head {
      position: relative;
      border-bottom: 1px solid $linesColor;
      padding: 15px;
      text-align: center;
      font-weight: bold;
      .close {
        font-size: 14px;
        position: absolute;
        right: 16px;
        top: 6px;
        bottom: 0px;
        margin: auto;
        height: 30px;
        padding: 5px;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .body {
      padding: 16px;
    }
  }
}

@media screen and (max-width: 768px) {
  .Modal > .content {
    transition: top 0.3s 0.2s;
    top: 100vh;
    position: relative;
    width: 500px;
    max-width: 100vw;
    background: white;
    border-radius: 24px;
    margin: auto;
    min-height: 100%;
    border-radius: 0px;
  }
}
