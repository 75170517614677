.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  }
  .clusterPin {
    color:  $primaryColor;
    font-weight: bold;
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    border: 2px solid #A1BDFF;

    p {
      margin: 0px;
      text-align: center;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {      
      background-color: $primaryColor;
      border-color: $primaryColor;
      color: white;
      transform: scale(1.1);
    }
  }

  .pin{
    background-color: white;
    cursor: pointer;
    top: 0px;
    position: absolute;
    border-radius: 10px;
    padding: 5px 8px;
    color: #0055FB;
    margin: -2px -34px 20px;
    font-weight: 500;
    border: 2px solid #0055FB;
    font-size: 14px;
    line-height: 20px;
    transition: transform .25s ease;

    &:before {
      content: " ";
      border-left: 8px solid transparent;
      position: absolute;
      bottom: -17px;
      left: 0px;
      border-right: 8px solid transparent;
      border-top: 8px solid #0055FB;
      border-bottom: 8px solid transparent;
      right: 0px;
      width: 7px;
      margin: auto;
    }
    &:after {
      content: " ";
      border-left: 8px solid transparent;
      position: absolute;
      bottom: -14px;
      left: 0px;
      border-right: 8px solid transparent;
      border-top: 8px solid white;
      border-bottom: 8px solid transparent;
      right: 0px;
      width: 8px;
      margin: auto;
    }
    &.active, &:hover {
      &:after, &:before {
        border-top: 8px solid #0055FB;
      }
      background-color: #0055FB;
      color: white;
      border-color: #0055FB;
      transform: scale(1.1);
    }
  }

  .visited-marker {
    color: #555A64;
    border: 2px solid #555A64 ;

    &:before {
      border-top: 8px solid #555A64;
    }

    &:after {
      border-top: 8px solid white;
    }
    
  }

  .bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }
  
  .pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    animation-delay: 1.1s;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }
  
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }
  
    80% {
      transform: translateY(-10px) rotate(-45deg);
    }
  
    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }