$font: "Satoshi", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$headerHeight: 70px;

$borderRadius: 32%;
$linesColor: #DEE2E6;
$navbarColor: white;
$primaryColor: #0055FB;
$primaryColor2: #D9E6FE;
$secondaryColor: #ededfb;
$textColor: #676767;
$textColor2: #6C757D;
$greenColor: #3CD278;
$borderInputColor: #DEE2E6;
$greyBg: #DEE2E6;
$lightGreyBg: #F8F8F8;
$bgGrey: #EEE3FF;
$violet: #6622F2;
$small: 8px;
$medium: 16px;
$large: 24px;
$x-large: 32px;

$likeColor: #fd8d5d;