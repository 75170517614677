.DetailsBar {
  .price-desc {
    .desc {
      margin-top: 4px;
    }
  }

  .content-detail {
    background-color: #f8f8f8;
  }
  z-index: 9;
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  .content-detail {
    box-shadow: 0px 10px 25px #6c757d;
    border-radius: 24px 24px 0px 0px;
    background-color: $lightGreyBg;
    min-height: 80px;
    width: 100;

    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
  }
}

.ActionButtons {
  .buttonsBox {
    @media screen and (max-width: 768px) {
      align-items: center;
      position: relative;
      justify-content: space-evenly;
      .ButtonLabel {
        margin-left: 8px !important;
        margin-right: 8px;
        padding: 0px;
        width: auto;
        margin: 0px !important;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
        span {
          padding-bottom: 2px;
        }
      }
    }
  }
  .ButtonLabel {
    p {
      font-size: 12px;
      top: 2px;
      position: relative;
    }
  }
  @media screen and (max-width: 768px) {
    margin: auto;
  }
  .ButtonLabel {
    margin-left: 8px !important;
    margin-right: 8px;
    p {
      font-size: 14px !important;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    p {
      margin: 0px;
      top: 0px !important;
    }
  }

  .share {
  }
  .hidden {
    &:hover,
    &.active {
      color: $primaryColor !important;
    }
  }
  .like {
    &:hover,
    &.active {
      color: $likeColor !important;
    }
  }
}

.ButtonLabel {
  transition: all 0.2s ease;
  padding: 14px 20px 8px;
  color: black;
  border: 1px solid $greyBg;
  border-radius: 10px;
  display: inline-block;
  min-width: 48px;
  min-height: 48px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: $greyBg;
  }
  &.blue {
    background-color: $primaryColor;
    border: 1px solid $primaryColor;
    color: white;
    &:hover {
      background-color: $greyBg;
      border: 1px solid $greyBg;
      color: black;
    }
  }
  &.lightGreen {
    border: 1px solid #e2f9f6;
    color: #21cfbc;
  }
  &.orange {
    border: 1px solid #fff7e9;
    color: #fead29;
  }
  &.transparent {
    background: transparent !important;
    border: 1px solid transparent;
  }

  & > span + p {
    top: -3px !important;
  }
}
