.customInputIcon {
  border-color: $borderInputColor !important;
  border: 1px solid $borderInputColor;
  border-radius: 10px;
  .form-control,
  .input-group-text {
    background-color: transparent !important;
  }
  span,
  input {
    border: none;
  }
  .input-group-prepend {
    width: 40px;
    span {
      color: $primaryColor;
    }
  }
  input,
  input:focus,
  textarea:focus,
  select:focus {
    color: $primaryColor;
    font-size: 12px;
    height: 38px;
    padding-left: 0px;
    font-weight: bold;
    outline: none !important;
    box-shadow: none !important;
    border-left: none;
  }
}

.CustomInputTel {
  position: relative;
  &.with-icon {
    input {
      padding-left: 34px;
    }
  }
  .form-icon {
    font-size: 20px;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  &.with-label {
    height: auto;
    padding: 3px 5px;
    .customInputLabel {
      margin: 0px 15px;
      font-size: 12px !important;
      color: grey !important;
    }
    input {
      padding: 0px 15px;
      height: 28px;
      margin-top: -2px !important;
    }
  }
  &.disabled {
    background-color: #ececec;
  }
  input[disabled] {
    background-color: transparent;
  }

  :active {
    border: 2px solid #0055fb !important;
    border-radius: 8px;
  }
}

.customButton {
  button p {
    letter-spacing: -0.13px;
  }
  @media screen and (max-width: 768px) {
    button p {
      letter-spacing: 0.11px;
    }
  }
  &.black {
    button {
      border-radius: 10px;
      background: black;
      font-size: 12px;
      height: 40px;
      border: 1px solid black;
      color: white;
    }
  }
  button {
    font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
    font-weight: 300;
    p {
      position: relative;
    }
  }
  &.solid {
    button {
      background-color: $primaryColor !important;
      border: none;
      transition: all 0.2s ease;
      border-radius: 8px;
      font-size: 14px;
      height: 40px;
      .form-icon {
        color: white;
      }
      .default-icon {
        transition: all 0.2s ease;
        &:before {
          transition: all 0.2s ease;
          display: inline-block;
          content: '';
          width: 12px;
          height: 12px;
          background-color: #4e4ee6;
          margin: auto;
          border-radius: 24px;
          top: 2px;
          position: relative;
          left: -5px;
          margin-right: 3px;
        }
      }
    }
  }
  &.clasic {
    button {
      transition: all 0.2s ease;
      border-radius: 8px;
      background: white;
      font-size: 12px;
      height: 48px;
      border-radius: 8px;
      box-shadow: none;
      border: 1px solid #dee2e6;
      height: 48px;
      color: $primaryColor;
      .default-icon {
        transition: all 0.2s ease;
        &:before {
          transition: all 0.2s ease;
          display: inline-block;
          content: '';
          width: 12px;
          height: 12px;
          background-color: #4e4ee6;
          margin: auto;
          border-radius: 24px;
          top: 2px;
          position: relative;
          left: -5px;
          margin-right: 3px;
        }
      }
      &.active {
        .form-width-icon {
          .form-icon {
            color: white;
          }
        }
      }
      &:hover {
        .form-width-icon {
          .form-icon {
            color: white;
          }
        }
        background-color: $primaryColor;
        color: white;
        /* filter: grayscale(0.45); */
      }
    }
  }
  &.simpleBtn {
    &:hover {
      button {
        background-color: transparent !important;
        color: $primaryColor;
        border-color: $primaryColor;
        // box-shadow: 0px 0px 2px $primaryColor;
      }
    }
  }
  .form-width-icon {
    top: 1px;
    .form-icon {
      transition: all 0.2s ease;
      top: -1px;
    }
  }

  &.size-64 {
    button {
      border-radius: 8px;
      height: 64px !important;
    }
  }
}

.biggerInput {
  &.customButton {
    button {
      font-size: 18px !important;
      height: 48px !important;
    }
  }
  &.selectInputBox {
    &.customInputBox {
      height: 48px;
    }
  }
  &.customInput {
    input {
      height: 48px !important;
    }
  }
}
.CustomInputFilter {
  padding-left: 0px;
  border-radius: 8px !important;
  border: 1px solid #dee2e6;
  max-width: 200px;
  box-shadow: none !important;
  margin-top: 8px !important;
  padding: 8px !important;
  min-width: 108px;
  text-align: center;
  height: 48px;
}
.CustomInputFilterDisabled {
  border: none !important;
  color: #6c757d !important;
  text-align: left !important;
  padding-left: 0 !important;
}

.extraBiggerInput {
  &.customButton {
    button {
      border-radius: 24px !important;
      font-size: 16px !important;
      height: 64px !important;
    }
  }
  &.selectInputBox {
    &.customInputBox {
      height: 64px;
      border-radius: 24px !important;
    }
  }
  &.customInput {
    input {
      height: 64px !important;
      border-radius: 24px !important;
    }
  }
}
.customInput {
  input {
    padding-bottom: 0 !important;
  }
}

.customInputSearchSelect {
  width: 100%;
  position: relative;
  .selectInputContent {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    & > svg {
      margin: 16px;
      margin-left: 8px;
      cursor: pointer;
      color: #0055FB;
    }
    .selectInputBox {
      box-shadow: none;
      border-radius: 8px;
      border: 1px solid #dee2e6;
      height: 48px;

      .defaultValue {
        color: grey;
      }
      .defaultValueHide {
        display: none;
      }
      .inputValue {
        letter-spacing: -0.13px;
      }
      @media screen and (max-width: 768px) {
        .inputValue {
          letter-spacing: -0.11px;
        }
      }
      background-color: white;
      &.disabled {
        background-color: #ececec;
      }

      &.size-48 {
        border-radius: 8px;
        height: 48px;
      }
      &.size-64 {
        border-radius: 8px;
        height: 64px;
        .form-width-icon {
          padding-left: 34px;
        }
        .input-group {
          margin-top: 11px;
          position: relative;
          .form-icon {
            position: absolute;
            font-size: 17px;
            left: 5px;
            color: #0055fb;
          }
        }
      }
      transition: all 0.2s ease;
      width: 100%;
      position: relative;
      .content {
        cursor: pointer;
        height: 100%;
        padding: 8px;

        .inputValue {
          width: 100%;
          z-index: -1;
          background: transparent;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          position: relative;
          top: 1px;
          margin: auto;
        }
        .inputWithLabel {
          align-content: flex-end;
          padding-left: 0.5rem;
        }
        .input-group {
          z-index: 1;
          height: 100%;
          margin-top: 0;
          align-items: center;

          .form-icon,
          .form-select-icon {
            top: 50%;
            transform: translateY(-50%);
          }
          .form-select-icon {
            transform: rotate(90deg);
          }

          input {
            width: 100%;
            height: 100%;
            border: 0;
            outline: none;
            position: relative;
            top: 1px;
            background: transparent;
          }
        }
      }
      .hidden {
        opacity: 0;
      }

      &.customInputSelect {
        .inputValue {
          padding-right: 30px;
        }
        .form-select-icon {
          position: absolute;
          transition: all 0.2s ease;
          right: 6px;
          font-size: 7px;
          color: grey;
          top: 8px;
          transform: rotate(90deg);
        }
      }
      &.selectInputBox {
        .inputValue {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
        }
      }
      .dropDownFlagSelectorPhone {
        padding: 0;
        margin: 0;
        text-align: center;
        background: #ffffff;
        box-sizing: border-box;
        color: #3faffa;
        font-size: 1.3rem;
        font-weight: 500;
        border-radius: 0.5rem;
        width: 75px;
        margin-top: 5px;
        position: absolute;
        box-shadow: 0 0 4px #d2d2d2;
        text-align: left;
        padding-left: 8px;
        z-index: 10;

        .itemClassic {
          &:hover {
            background: #f8f8f8;
            cursor: pointer;
            p {
              color: $primaryColor;
            }
          }
        }

        .active {
          .itemClassic {
            p {
              color: $primaryColor !important;
            }
          }
        }

        .scrollContainer {
          max-height: 200px;
          width: 100%;
          padding: 4px 0px;
          .ps__rail-y {
            right: 0px !important;
            max-width: 12px;
          }
        }
      }

      &:not(.disabled) {
        @media screen and (min-width: 768px) {
          &.active {
            .inputSelectOptions {
              z-index: 99;
              opacity: 1;
              margin-top: 4px;
              max-height: 212px;
              height: auto;
              background: white;
              .itemClassic {
                p:nth-child(1) {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
                &:hover {
                  background: #f8f8f8;
                  cursor: pointer;
                  p {
                    color: $primaryColor;
                  }
                }
              }
            }
          }
        }
        @media screen and (max-width: 768px) {
          &.active {
            .inputSelectOptions {
              z-index: 99;
              opacity: 1;
              margin-top: 4px;
              max-height: 212px;
              height: auto;
              background: white;
              .itemClassic {
                p:nth-child(1) {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
                &:hover {
                  background: #f8f8f8;
                  cursor: pointer;
                  p {
                    color: $primaryColor;
                  }
                }
              }
            }
          }
        }
        &.customInputSelect {
          &.active {
            // &.active
            .form-select-icon {
              transform: rotate(270deg);
            }
            border: 2px solid $primaryColor !important;
          }
        }
        &.selectInputBox {
          &:hover,
          &.active {
            color: $primaryColor;
            border: 2px solid $primaryColor !important;
          }
          &:hover {
            .form-select-icon,
            .inputSelectOptions {
              transition: all 0.4s 0.2s ease;
            }
          }
        }
      }
      &:hover {
        border-color: $primaryColor;
        color: $primaryColor;
        border: 2px solid #0055fb;
        .form-select-icon {
          color: $primaryColor;
        }
      }
    }
  }

  .inputSelectOptionsMobile {
    border-radius: 0px 0px 24px 24px;
    border: 0px solid #dee2e6;
    position: absolute;
    width: 100%;
    .scrollContainer {
      max-height: 90vh;
      width: 100%;
      padding: 4px 0px;
      .ps__rail-y {
        right: 0px !important;
        max-width: 12px;
      }
    }
    .subitem {
      border-radius: 16px;
      border: #6c757d 1px solid;
      padding: 4px 8px;
      text-transform: capitalize;
      width: fit-content;
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .locationParent {
      padding: 4px 8px;
      text-transform: capitalize;
      width: fit-content;
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    .itemWrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .itemNameContainer {
      display: flex;
      flex-flow: column wrap;
    }
  }

  .inputSelectOptions {
    width: 100%;
    padding-bottom: 10px;
    .scrollContainer {
      max-height: 200px;
      width: 100%;
      padding: 4px 0px;
      .ps__rail-y {
        right: 0px !important;
        max-width: 12px;
      }
    }
    background-color: #fff;
    border-radius: 0px 0px 24px 24px;
    border: 1px solid #dee2e6;
    position: absolute;
    width: calc(100% + 2px);
    left: -1px;
    z-index: 9;
    transition: all 0.2s ease;
    overflow: hidden;
    z-index: 9;
    .active {
      .itemClassic {
        p {
          color: $primaryColor !important;
        }
      }
    }
    .itemClassic {
      overflow-x: hidden;
      p:nth-child(1) {
        color: #6c757d;
        font-weight: 500;
        overflow-x: clip;
      }
      p:nth-child(2) {
        background-color: #d9e6fe;
        border-radius: 4px;
        top: 0px;
        position: relative;
        padding: 3px 5px 1px;
        line-height: 14px !important;
      }
      transition: all 0.2s ease;
      border-radius: 4px;
      width: 210px;
    }
    .subitem {
      border-radius: 16px;
      border: #6c757d 1px solid;
      padding: 4px 8px;
      text-transform: capitalize;
      width: fit-content;
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .locationParent {
      padding: 4px 8px;
      text-transform: capitalize;
      width: fit-content;
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    .itemWrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .itemNameContainer {
      display: flex;
      flex-flow: column wrap;
    }
  }
}

.customInputSelect {
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid #DEE2E6;
  height: 48px;

  .defaultValue {
    color: grey;
  }
  .defaultValueHide {
    display: none;
  }
  .inputValue {
    letter-spacing: -0.13px;
  }
  @media screen and (max-width: 768px) {
    .inputValue {
      letter-spacing: -0.11px;
    }
  }
  background-color: white;
  &.disabled {
    background-color: #ececec;
  }

  &.size-48 {
    border-radius: 8px;
    height: 48px;
  }
  &.size-64 {
    border-radius: 8px;
    height: 64px;
    .form-width-icon {
      padding-left: 34px;
    }
    .input-group {
      margin-top: 11px;
      position: relative;
      .form-icon {
        position: absolute;
        font-size: 17px;
        left: 5px;
        color: #0055fb;
      }
    }
  }
  transition: all 0.2s ease;
  width: 100%;
  position: relative;
  .content {
    cursor: pointer;
    height: 100%;
    padding: 8px;

    .inputValue {
      width: 100%;
      z-index: -1;
      background: transparent;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      top: 1px;
      margin: auto;
    }
    .inputWithLabel {
      align-content: flex-end;
      padding-left: 0.5rem;
    }
    .input-group {
      z-index: 1;
      height: 100%;
      margin-top: 0;
      align-items: center;

      .form-icon,
      .form-select-icon {
        top: 50%;
        transform: translateY(-50%);
      }
      .form-select-icon {
        transform: rotate(90deg);
      }

      input {
        width: 100%;
        height: 100%;
        border: 0;
        outline: none;
        position: relative;
        top: 1px;
        background: transparent;
      }
    }
  }
  .hidden {
    opacity: 0;
  }

  &.customInputSelect {
    .inputValue {
      padding-right: 30px;
    }
    .form-select-icon {
      position: absolute;
      transition: all 0.2s ease;
      right: 6px;
      font-size: 7px;
      color: grey;
      top: 8px;
      transform: rotate(90deg);
    }
  }
  &.customInputSearchSelect {
    .inputValue {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
    }
  }
  .dropDownFlagSelectorPhone {
    padding: 0;
    margin: 0;
    text-align: center;
    background: #ffffff;
    box-sizing: border-box;
    color: #3faffa;
    font-size: 1.3rem;
    font-weight: 500;
    border-radius: 0.5rem;
    width: 75px;
    margin-top: 5px;
    position: absolute;
    box-shadow: 0 0 4px #d2d2d2;
    text-align: left;
    padding-left: 8px;
    z-index: 10;

    .itemClassic{
      &:hover {
        background: #f8f8f8;
        cursor: pointer;
        p {
          color: $primaryColor;
        }
      }
    }

    .active {
      .itemClassic {
        p {
          color: $primaryColor !important;
        }
      }
    }

    .scrollContainer {
      max-height: 200px;
      width: 100%;
      padding: 4px 0px;
      .ps__rail-y {
        right: 0px !important;
        max-width: 12px;
      }
    }
  }

  .inputSelectOptions {
    width: 100%;
    padding-bottom: 10px;
    .scrollContainer {
      max-height: 200px;
      width: 100%;
      padding: 4px 0px;
      .ps__rail-y {
        right: 0px !important;
        max-width: 12px;
      }
    }
    opacity: 0;
    border-radius: 0px 0px 24px 24px;
    border: 1px solid #DEE2E6;
    position: absolute;
    width: calc(100% + 2px);
    left: -1px;
    z-index: 9;
    max-height: 0px;
    transition: all 0.2s ease;
    overflow: hidden;
    z-index: 9;
    .active {
      .itemClassic {
        p {
          color: $primaryColor !important;
        }
      }
    }
    .itemClassic {
      overflow-x: hidden;
      p:nth-child(1) {
        color: #6C757D;
        font-weight: 500;
        overflow-x: clip;
      }
      p:nth-child(2) {
        background-color: #d9e6fe;
        border-radius: 4px;
        top: 0px;
        position: relative;
        padding: 3px 5px 1px;
        line-height: 14px !important;
      }
      transition: all 0.2s ease;
      border-radius: 4px;
      width: 210px;
    }
    .subitem {
      border-radius: 16px;
      border: #6C757D 1px solid;
      padding: 4px 8px;
      text-transform: capitalize;
      width: fit-content;
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .locationParent {
      padding: 4px 8px;
      text-transform: capitalize;
      width: fit-content;
      font-family: 'Satoshi';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    .itemWrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .itemNameContainer {
      display: flex;
      flex-flow: column wrap;
    }
  }
  &:not(.disabled) {
    @media screen and (min-width: 768px) {
      &.active {
        .inputSelectOptions {
          z-index: 99;
          opacity: 1;
          margin-top: 4px;
          max-height: 212px;
          height: auto;
          background: white;
          .itemClassic {
            p:nth-child(1) {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            &:hover {
              background: #f8f8f8;
              cursor: pointer;
              p {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      &.active {
        .inputSelectOptions {
          z-index: 99;
          opacity: 1;
          margin-top: 4px;
          max-height: 212px;
          height: auto;
          background: white;
          .itemClassic {
            p:nth-child(1) {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            &:hover {
              background: #f8f8f8;
              cursor: pointer;
              p {
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
    &.customInputSelect {
      &.active {
        // &.active
        .form-select-icon {
          transform: rotate(270deg);
        }
        border: 2px solid $primaryColor !important;
      }
    }
    &.customInputSearchSelect {
      &:hover,
      &.active {
        color: $primaryColor;
        border: 2px solid $primaryColor !important;
      }
      &:hover {
        .form-select-icon,
        .inputSelectOptions {
          transition: all 0.4s 0.2s ease;
        }
      }
    }
  }
  &:hover {
    border-color: $primaryColor;
    color: $primaryColor;
    border: 2px solid #0055fb;
    .form-select-icon {
      color: $primaryColor;
    }
  }
}

.customInputLabel {
  font-weight: bold;
  margin-left: 6px;
}
.customInput {
  background-color: white;
  overflow: hidden;
  input {
    border-radius: 8px;
    border: 1px solid #dee2e6;
    height: 48px;
    background: none;
    outline: none;
    padding: 8px;
    padding-left: 16px;
    &[disabled] {
      background-color: #ececec;
    }
    &:hover[disabled] {
      border: none !important;
    }
    &:active,
    &:hover,
    &:focus {
      border: 2px solid #0055fb !important;
    }
  }
  &.small-input {
    height: 66px !important;
    input {
      padding: 8px !important;
    }
  }
  &.with-label {
    height: auto;
    padding: 3px 5px;
    .customInputLabel {
      margin: 0px 15px;
      font-size: 12px !important;
      color: grey !important;
    }
    input {
      padding: 0px 15px;
      height: 28px;
      margin-top: -2px !important;
    }
  }
  &:not(.disabled) {
    &.active {
      border: 1px solid $primaryColor !important;
    }
  }
  &.md-input {
    height: 48px !important;
  }
}

.form-icon {
  position: absolute;
  top: 2px;
  font-size: 18px;
  left: 8px;
}
.form-width-icon {
  &.only-icon {
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    width: 16px;
    height: 16px;
    font-size: 16px;
    padding-left: 0px;
  }
  padding-left: 40px;
}

.flagSelector {
  &.customInputSelect {
    min-width: 68px;
    height: 48px;
    border-radius: 8px;
    .content {
      position: relative;
      top: -2px;
      .form-select-icon {
        top: 18px;
      }
    }
  }
}
.Floating {
  display: inline-block;
  background-color: white;
  margin-bottom: 10px;
  margin-right: 16px;
  box-shadow: 0px 10px 25px #6c757d0f;
  padding: 10px 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  display: none; // TO COMMENT
  @media screen and (max-width: 768px) {
    & {
      display: none;
    }
    &.active {
      position: fixed;
      left: 0px;
      top: 69px;
      right: 0px;
      width: 100%;
      height: 44px;
      border-radius: 0px 0px 10px 10px;
      font-size: 20px;
      display: flex;
      align-items: center;
      z-index: 8;
      .blueDot {
        background-color: $primaryColor2;
        box-shadow: 0px 0px 2px $primaryColor2;
        height: 24px;
        width: 24px;
        margin-right: 15px;
      }
    }
  }
}

.customTextArea {
  textarea {
    min-height: 180px;
    border-radius: 8px;
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    border: 2px solid $borderInputColor;
    overflow: hidden;
  }
}

.selectInputBox {
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #dee2e6;
  height: 48px;
  .input-group {
    margin-top: 0px !important;
    .form-icon {
      bottom: 0px;
      top: 0px;
      height: 20px;
    }
    .inputValue {
      align-items: center;
      vertical-align: middle;
      justify-content: start;
      display: flex;
    }
  }
}

.customListSelect {
  &.inline {
    display: inline-block !important;
    .customButton {
      display: inline-block !important;
      margin-bottom: 6px !important;
    }
  }
}
.label {
  display: inline-block;
  position: absolute;
  margin: 0;
  transform: translate(12px, 12px);
  transition: all 150ms ease-in;
  color: #808080;
  font-weight: 400;
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 290px;
  z-index: 1;
  padding-right: 1rem;
}
.label-top {
  transform: translate(12px, 3px);
  font-size: 0.7em;
  letter-spacing: 0px;
  color: #6c757d;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff,
    0 -2px 0 #fff;
  padding-right: 0;
}
.left-6 {
  left: 24px;
}
@media screen and (min-width: 600px) {
  .label {
    max-width: 290px;
  }
  .label-top {
    font-size: 0.8em;
  }
}
@media screen and (min-width: 992px) {
  .label {
    max-width: 290px;
  }
}

.invalid-feedback {
  display: block;
}

.smallSelect {
  max-width: 80px !important;
}

.customInputSearchSelectItem {
  display: flex;
  flex-direction: row;
  padding: 16px 8px;
  margin: 0px 16px;
  max-width: calc(100% - 32px);
  border-radius: 4px;
  border-bottom: 1px solid #f0f0f0;
  align-items: center;
  color: #9b9b9b;

  &:hover {
    padding: 16px 24px;
    max-width: 100%;
    margin: 0px;
    background: #f8f8f8;
    cursor: pointer;

    & p,
    & svg {
      color: #0055fb !important;
    }
  }
}

.list-subtitle {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #9b9b9b;
  width: 100%;
  padding: 16px;
}

.search-icon {
  padding-right: 2px;
  display: flex;
  align-items: center;
}
