.Footer {
  padding-top: 40px;

  .logo-bucket {
    top: -5px;
    position: relative;
    margin-right: 5px;
    img { 
      position: relative;
      top: 3px;
      width: 125px;
    }
  }

  .comun-list {
    a { text-decoration: none !important; transition: all 0.2s ease;}
    p { letter-spacing: -0.13px; transition: all 0.2s ease;}
    @media screen and (max-width: 768px) {
      p { letter-spacing: -0.11px; }
    }
  }
}