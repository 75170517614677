.FaqMoreText {
  position: relative;
  &.active {
    .content { max-height:500px; }
    .title {
      span:before { content:"-"; }
    }
  }
  .title {
    background-color: $lightGreyBg;
    display: flex;
    justify-content: space-between;

    padding: 10px;
    margin: 4px 0px;
    border-radius: 8px;
    cursor: pointer;
    p { margin-bottom: 0px; color: $textColor2; }
    span:before { content:"+";  color: $textColor2; }
  }
  .content {
    transition: all 0.2s;
    overflow: hidden;
    max-height: 0px;
  }
}