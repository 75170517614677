.Favorites {
  .background {
    background-color: #F8F8F8;
    border-radius: 24px;
    padding: 30px 15px;
    position: relative;
    .heart {
      opacity: 0.2;
      position: absolute;
      left: 0px; right: 0px;
      top: 0px; bottom: 0px;
      margin: auto;
      background-color: $primaryColor;
      height: 200px;
      transform: rotate(-45deg);
      width: 200px;
      &:before,
      &:after {
        content: "";
        background-color: $primaryColor;
        border-radius: 50%;
        height: 200px;
        position: absolute;
        width: 200px;
      }
      &:before {
        top: -100px;
        left: 0;
      }
      &:after {
        top: 0px;
        right: -100px;
      }
    }
  }
}
