.Intro {
  .selectInputBox {
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid #DEE2E6;
    height: 48px;
  }
  button {
    border-radius: 8px;
    box-shadow: none;;
  }
  .intro-title {
    text-align: center;
    max-width: 600px;
    margin: auto;
    color: black;
    margin-top: 60px;

    @media screen and (max-height: 700px) {
      margin-top: 85px;
    }
  }
  .rombo-box {
    z-index: 1;
    position: relative;
    * {
      z-index: 2;
    }
    .icon-rombo-box-background {
      max-width: 250px;
      max-height: 250px;
      position: absolute;
      display: block;
      border-radius: 81px;
      transform: rotate(15deg);
      width: 15vw;
      height: 15vw;
      background-color: #ffeae2;
      opacity: 1;
      z-index: -1;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin-right: auto;
      max-width: 245px;
      max-height: 245px;
    }
    .icon-rombo-box {
      display: block;
      border-radius: 24px;
      width: 56px;
      height: 56px;
      background-color: #fd8d5d;
      margin-bottom: 24px;
    }
    &.green-rombo-box {
      .icon-rombo-box-background {
        background-color: #e2f9f6;
        transform: rotate(-15deg);
      }
      .icon-rombo-box {
        background-color: #21cfbc;
      }
    }

    &.orange-rombo-box {
      .icon-rombo-box-background {
        background-color: #fff7e9;
        transform: rotate(-45deg);
      }
      .icon-rombo-box {
        background-color: #ffc837;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .customButton.solid button {
      height: 48px;
    }
    .customInputSelect,
    .customInputSearchSelect {
      height: 48px;
      border-radius: 8px;
      .content .inputValue {
        display: flex;
        align-items: center;
      }

      & > svg {
        margin: 16px;
        margin-left: 8px;
        cursor: pointer;
        color: #0055FB;
      }

      .form-icon {
        position: absolute;
        top: 0px;
        font-size: 18px;
        left: 8px;
        bottom: 0px;
        margin: auto;
        align-items: center;
        display: flex;
      }
    }
  }
  
}
