.details-tabs {
  @keyframes faden {
    from { opacity: 0; margin-top: 30px; }
    to { opacity: 1; margin-top: 0px; }
  }
  .details-item {
    opacity: 0; 
    max-height: 0px;
    margin-top: 0px;
    &.active {
      margin-top: 30px;
      display: block;
      max-height: 100%;
      animation: faden 0.2s 0.2s ease forwards;
    }
  }
  .details-tabs-navbar {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    .navbar-item {
      padding: 9px 15px;
      margin: 0px;
      color: $textColor2;
      border-radius: 8px;
      transition: all 0.2s ease;
      font-size: 14px;
      line-height: 14px;
      cursor: pointer;
      min-width: 79px;
      &.active {
        background-color: $primaryColor;
        color: white;
      }
    }
  }
}