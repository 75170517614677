.social {
  display: flex;
  justify-content: center;
  position: relative;
  .social-box {
    .icon-copy_frame_icon {
      color: grey;
    }
    &.copied {
      background-color: #aec5f1;
      .icon-copy_frame_icon {
        color: $primaryColor;
      }
    }
    margin-bottom: 12px;
    margin-left: 16px;
    margin-right: 16px;
    background-color: $linesColor;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    p {
      position: absolute;
      bottom: -24px;
      font-size: 14px !important;
      color: grey;
    }
  }
  &.social-aptuno {
    justify-content: start;
    display: flex;
    .social-box {
      margin-left: 0px;
      margin-right: 16px;
      background-color: $linesColor;
      width: 48px;
      height: 48px;
      border-radius: 100%;
    }
  }
}
