.filterNav {
  button {
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid #DEE2E6;
    height: 48px;
    min-height: 48px;
  }
  button.active, button:hover {
    background-color: #0055FB;
    color: #FFFFFF;
    border: 2px solid #0055FB;
    box-shadow: none!important;
  }

  @media screen and (max-width: 768px) {
    .form-width-icon.only-icon {
      .form-icon.icon-filter_fill {
        left: 0px;
        right: 0px;
      }
    }
    .content .body {
      max-height: calc(100vh - 190px) !important;
    }
  }
  position: fixed;
  width: 100%;
  background: white;
  padding: 12px 15px !important;
  border-radius: 0px 0px 24px 24px;
  box-shadow: 0px 0px 8px #6c757d0f;
  transition: all 0.2s ease;
  z-index: 11;
  &.hide {
    overflow: hidden;
    height: 0px;
    padding: 0px !important;
  }

  .right {
    position: absolute;
    right: 10px;
    top: 25px;
  }
  .left {
    margin-left: 26px;
  }
  .customButton {
    button {
      border: 1px solid #DEE2E6;
      color: #000000;
    }
    .form-width-icon.only-icon {
      width: 100%;
      margin: 0px;
    }
  }
  .selectedInput {
    background-color: #E6EDFF;
    border-color: #E6EDFF!important;
    color: #0055FB;
  }
  .customInput {
    width: 230px;

    &:focus {
      border: 1px solid #0055fb !important;
    }
  }
  .label {
    transform: translate(12px, 18px);
  }
  .label-top {
    transform: translate(12px, 7px);
  }

  .customButton {
    &:hover {
      button {
        border: 2px solid #0055fb !important;
        background-color: white !important;
        color: $primaryColor !important;
        span {
          color: $primaryColor !important;
        }
        border: 1px solid $primaryColor;
      }
    }
  }
  .priceBtn {
    button p {
      text-align: left;
    }
  }
  @media screen and (max-width: 768px) {
    form {
      & > div:nth-child(1) {
        width: 100% !important;
        justify-content: space-around;
      }
    }
    .gridRow {
      display: flex;
      width: 90% !important;
      margin: auto;
      .searchSelectContainer {
        width: calc(72% - 5px) !important;
        margin: 0px !important;
      }
      .customButton {
        width: calc(25% - 5px) !important;
        margin-right: 0px !important;

        .form-width-icon {
          padding-left: 32px;
        }
        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .customInputSelect {
        width: calc(50% - 5px) !important;
      }
      .priceBtn {
        width: 100% !important;

        button p {
          padding-left: 0px;
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }
        button span {
          left: 0 !important;
        }
      }
    }
  }
}
.gridRow {
  width: 100%;
  margin: auto;
  display: grid;
  grid-gap: 16px;
  @media screen and (max-width: 992px) {
    grid-template-columns: 300px 224px 224px 145px;
  }
  @media screen and (min-width: 992px) {
    grid-template-columns: 300px 224px 224px 145px;
  }
  @media screen and (max-width: 880px) and (min-width: 768px) {
    grid-template-columns: 180px 180px 180px 145px;
  }
  grid-template-rows: auto;
  & > .form-group {
    width: 100% !important;
  }
}
.shimmerFilter {
  position: relative;
  width: 100%;
  margin-left: 10%;
  height: 40px;
  span {
    display: block;
    width: 100%;
    height: 40px;
    background-color: #77707014;
    border-radius: 8px;
  }
}

.filterModal {
  .title-modal {
    width: 100%;
  }
  .icon-blue {
    color: #0055fb;
    font-size: 24px;
    width: 24px;
    height: 24px;
  }
  .content {
    width: 600px !important;
    .body {
      max-height: 60vh;
      overflow-y: auto;
      overflow-x: hidden !important;
    }
    .head {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: space-between;
    }
  }
  .customListSelect {
    .customButton {
      padding-right: 4px;
      button {
        border-radius: 24px !important;
        min-width: 33px !important;
        min-height: 36px;
        font-size: 14px;
        line-height: 20px;
      }
      &:hover {
        button {
          color: black;
          background-color: white;
          width: fit-content !important;
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }
      button:focus {
        background-color: white !important;
        color: $primaryColor !important;
        border-width: 1px !important;        
        box-shadow: none !important;
      }
      button.active {
        background-color: #e6edff !important;
        color: $primaryColor !important;
        border-width: 2px !important;
        font-weight: 600 !important;
        box-shadow: none !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .customListSelect {
      display: block !important;
    }
    .customButton {
      display: inline-block;
      margin-top: 8px !important;
    }
    .body {
      padding: 16px!important;
    }
  }
}

.mobile-filter-absolute {
  transition: all 0.2s ease;
  background-color: white;
  position: fixed;
  top: -70px;
  padding-top: 10px;
  left: 0px;
  right: 0px;
  &.active {
    top: 70px;
    z-index: 8;
  }
  .form-width-icon.only-icon {
    width: 100%;
    margin: 0px;
  }
}

.area-box {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
  gap: 16px;

  .customInput {
    width: 108px;
    text-align: center !important;
    box-shadow: none;
    border: 1px solid #DEE2E6;
    input {
      text-align: center;
      padding: 0;
      border-radius: 8px;
      height: 42px;
    }
    margin: 5px;
  }
}
