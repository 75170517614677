.WhoWeAre {
  .img {
    border-radius: 8px;
    overflow: hidden;
    &.wwa_1 {
      height: 460px;
      width: 100%;
      max-width: 360px;
      margin-left: auto;
    }
    &.wwa_2 {
      height: 460px;
      width: 100%;
      max-width: 760px;
    }
    &.wwa_3 {
      height: 316px;
      width: 100%;
      max-width: 460px;
    }
    .bkg-image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .values-box {
    min-height: 320px;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    .img {
      display: flex;
      margin: auto;
      width: 152px;
      height: 152px;
      border-radius: 61px;
      background-color: $greyBg;
    }
    p { margin-top:10px; margin-bottom: 10px; text-align:center; }
  }

  .people-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    padding: 18px;
    min-height: 200px;
    .img {
      display: flex;
      margin: auto;
      margin-bottom: 10px;
      width: 100%;
      max-width: 100%;
      border-radius: 48px;
      background-color: #F8F8F8;
    }
    p { margin-left: 4px; margin-right: auto; display: flex; }
  }
 
  .img.punch_it {
    border-radius: 0px;
    height: 800px;
    @media screen and (max-width:768px) {
      & { height: 400px; }
    }
    .bkg-image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
.space-top {
  margin-top: 80px;
}
