.details-gallery {
  @media screen and (max-width: 768px) {
    .slick-dots {
      opacity: 0.8;
      color: white;
      position: absolute;
      left: 0px;
      right: 0px;
      margin: auto;
      bottom: 10px !important;
      display: flex !important;
      vertical-align: middle;
      align-items: center;
      justify-content: center !important;
      font-size: 56px;
      margin-top: 100%;
      z-index: 4;
      height: 30px;
      cursor: pointer;
    }
    .slick-dots {
      justify-content: start !important;
      max-width: calc(8px * 6);
      overflow-x: hidden;
      overflow-y: hidden;
      scroll-behavior: smooth;
      li {
        width: 8px;
      }
      li button {
        &:before {
          opacity: 1;
          width: 8px !important;
          height: 8px !important;
        }
        font-size: 0;
        color: #fff;
        top: -1px;
        position: relative;
        width: 8px !important;
        height: 8px !important;
        padding: 0px !important;
      }
      li.slick-active {
        width: 16px !important;
        height: 20px !important;
        button {
          width: 16px !important;
          height: 18px !important;
          &:before {
            opacity: 1;
            width: 16px !important;
            height: 18px !important;
          }
          opacity: 1;
        }
      }
    }
  }

  .bigger {
    .gallery-item {
      height: 300px;
      @media screen and (max-width: 768px) {
        height: 224px;
        margin-bottom: 2px;
      }
      & > div {
        border-radius: 24px;
        cursor: pointer;
      }
    }
  }
  .smaller {
    .slick-list {
      margin-right: -8px;
    }
    .gallery-item {
      outline: none !important;
      padding-right: 10px;
      height: 75px;
      max-width: 116px;
      & > div {
        border-radius: 8px;
      }
    }
  }
  .gallery-label-buttons {
    display: flex;
    align-items: center;
    .gallery-buttons {
      width: 100px;
      text-align: center;
      .prev-arrow-btn {
        outline: none;
        background-color: transparent;
        position: relative;
        border: none;
        width: 20px;
        height: 20px;
        &:hover {
          &:after {
            color: $textColor2;
          }
        }
        &:after {
          top: 0px;
          content: "\e907" !important;
          transform: rotate(180deg);
          font-family: "icomoon" !important;
          font-style: normal;
          font-weight: normal;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
        }
      }
      .next-arrow-btn {
        outline: none;
        background-color: transparent;
        position: relative;
        border: none;
        width: 20px;
        height: 20px;
        &:hover {
          &:after {
            color: $textColor2;
          }
        }
        &:after {
          top: 0px;
          content: "\e907" !important;
          font-family: "icomoon" !important;
          font-style: normal;
          font-weight: normal;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: absolute;
        }
      }
    }
  }
}

@keyframes zoomShow {
  from {
    opacity: 0;
    margin-top: 30px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}
