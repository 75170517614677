.calculatorBucket {
  .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    margin-left: 8px;
    color: #f0323c;
    font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
    font-weight: 200;
  }
  .phoneForm {
    .form-icon.icon-cellphone_icon {
      color: $primaryColor;
    }
    .customInput {
      width: calc(100% - 100px);

      input {
        height: 48px;
      }
    }
    .customInputSelect {
      width: 80px;
      border-radius: 8px;
      border: 1px solid #DEE2E6;
      height: 48px;
      .content {
        position: relative;
        top: -2px;
        .form-select-icon {
          top: 18px;
        }
      }
    }
  }
  .btn-primary {
    background-color: #0055fb !important;
    border: none;
    transition: all 0.2s ease;
    border-radius: 16px;
    font-size: 14px;
    height: 40px;
    color: white;
    width: 100%;
  }
}
