.details-multi-tabs {
  @keyframes faden {
    from {
      opacity: 0;
      margin-top: 30px;
    }
    to {
      opacity: 1;
      margin-top: 0px;
    }
  }
  .details-item {
    display: none;
    opacity: 0;
    margin-top: 30px;
    &.active {
      display: block;
      animation: faden 0.2s 0.2s ease forwards;
    }
  }
  .details-tabs-navbar {
    @media screen and (max-width: 768px) {
      .navbar-item {
        box-shadow: 0px 1px 25px #6c757d0f !important;
        width: 20% !important;
        margin: 0px 2.5% !important;
      }
    }
    // display: flex;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    .navbar-item {
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 16px;
      box-shadow: 0px 10px 25px #6c757d0f;
      padding: 10px 12px;
      border-radius: 8px;
      transition: all 0.2s ease;
      font-size: 14px;
      line-height: 14px;
      cursor: pointer;
      &.active {
      }
    }
  }
}
