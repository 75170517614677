.NewsLetter {
  background-color: black;
  border-radius: 24px;
  .lefty {
    width: 50%;
  }
  .righty {
    width: 50%;
    display: flex;
    .customInput {
      width: 100%;
      margin-right: 12px;
    }
  }

  @media screen and (max-width: 768px) {
    & .d-flex.align-items-center {
      flex-direction: column;
    }
    .lefty {
      width: 100%;
    }
    .righty {
      width: 100%;
    }
  }
}
