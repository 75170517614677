.magic-dots {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  &.only-grey-3 {
    z-index: 0;
    .dot {
      transition: all 0.2s ease-in-out;
      position: absolute;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 5px;
        border-radius: 100px;
        top: 5px;
      }
      &:nth-child(1) {
        width: 360px;
        height: 360px;
        top: 80px;
        left: 0px;
        transform: rotate(50deg) scale(0.8);
        opacity: 1;
        right: 0px;
        margin: auto;
        &:before {
          border-radius: 140px;
          background-color: #f8f8f8;
        }
      }
    }
  }
  &.only-grey-2 {
    z-index: 0;
    .dot {
      transition: all 0.2s ease-in-out;
      position: absolute;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 5px;
        top: 5px;
      }
      &:nth-child(1) {
        width: 220px;
        height: 220px;
        top: 80px;
        left: -122px;
        transform: rotate(50deg) scale(0.8);
        opacity: 1;
        &:before {
          border-radius: 90px;
          background-color: #eee3ff;
        }
      }
      &:nth-child(2) {
        width: 48vw;
        height: 90vh;
        bottom: 6vh;
        right: -82px;
        opacity: 1;
        transform: rotate(-5deg);
        &:before {
          border-radius: 200px;
          background-color: #eee3ff;
        }
      }
    }
    @media screen and (max-width: 768px) {
      .dot {
        &:nth-child(1) {
          left: -175px;
        }
        &:nth-child(2) {
          width: 100vw;
          bottom: -100px;
        }
      }
    }
  }
  &.only-grey {
    .dot {
      transition: all 0.2s ease-in-out;
      position: absolute;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 5px;
        top: 5px;
      }
      &:nth-child(1) {
        width: 220px;
        height: 220px;
        top: 80px;
        left: -122px;
        transform: rotate(50deg) scale(0.8);
        opacity: 1;
        &:before {
          border-radius: 90px;
          background-color: #f8f8f8;
        }
      }
    }
  }
  &.default {
    .dot {
      transition: all 0.2s ease-in-out;
      position: absolute;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 5px;
        top: 5px;
      }
      &:nth-child(1) {
        width: 220px;
        height: 220px;
        top: 80px;
        left: -122px;
        transform: rotate(50deg) scale(0.8);
        opacity: 1;
        &:before {
          border-radius: 90px;
          background-color: #fd8d5d;
        }
      }
      &:nth-child(2) {
        width: 328px;
        height: 328px;
        bottom: 16px;
        left: -80px;
        opacity: 1;
        transform: rotate(10deg) scale(0.8);
        &:before {
          border-radius: 90px;
          background-color: #21cfbc;
        }
      }
      &:nth-child(3) {
        width: 240px;
        height: 240px;
        bottom: 100px;
        left: calc(100% - 120px);
        opacity: 1;
        transform: rotate(30deg) scale(0.8);
        &:before {
          border-radius: 90px;
          background-color: #ffc837;
        }
      }

      &:nth-child(4) {
        @media screen and (max-width: 768px) {
          & {
            display: none;
          }
        }
        width: 300px;
        height: 300px;
        bottom: calc(50% - 150px);
        left: calc(50% - 150px);
        opacity: 1;
        transform: rotate(35deg) scale(0.8);
        &:before {
          border-radius: 120px;
          background-color: #f8f8f8;
        }
      }
    }
    @media screen and (max-width: 370px) {
      .dot {
        &:nth-child(1) {
          transform: rotate(50deg) scale(0.6);
        }
        &:nth-child(2) {
          transform: rotate(10deg) scale(0.6);
          left: -200px;
        }
        &:nth-child(3) {
          transform: rotate(30deg) scale(0.6);
        }
        &:nth-child(4) {
          transform: rotate(35deg) scale(0.6);
        }
      }
    }

    @media screen and (min-width: 1400px) {
      .dot {
        &:nth-child(1) {
          transform: rotate(50deg) scale(0.95);
        }
        &:nth-child(2) {
          transform: rotate(10deg) scale(0.95);
        }
        &:nth-child(3) {
          transform: rotate(30deg) scale(0.95);
        }
        &:nth-child(4) {
          transform: rotate(35deg) scale(0.95);
        }
      }
    }
    @media screen and (max-width: 768px) {
      & {
        .dot {
          &:nth-child(1) {
            top: 23%;
            left: -42%;
            transform: rotate(-25deg) scale(0.6);
          }
          &:nth-child(2) {
            top:-8%;
            left: -255px;
            transform: rotate(-10deg) scale(0.6);
          }
          &:nth-child(3) {
            top: 3%;
            left: 84%;
            transform: rotate(30deg) scale(0.6);
          }
          &:nth-child(4) {
            display: block;
            top: 6%;
            transform: rotate(35deg) scale(0.6);
          }
        }
      }
    }
  }
}
