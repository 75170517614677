.Press {
  padding-top: 20px;
  padding-bottom: 60px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: $lightGreyBg;
  .btn-box {
    width: 100px;
    display: flex;
    margin-left: auto;
  }
  .press-box {
    background-color: white;
    border-radius: 24px;
    padding: 16px;
    display: flex;
    height: 320px;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 16px;
    box-shadow: rgba(120, 120, 120, 0.06) 0px 10px 25px;

    .text p:nth-child(1) {
      font-size: 26px;
      text-transform: uppercase;
    }

    &:hover {
      box-shadow: rgba(108, 117, 125, 0.28) 0px 10px 25px;
      transition: all 0.3s ease 0.1s;
    }
  }
  .iconBox {
    width: 100%;
    height: 100px;
    padding: 16px;
    display: flex;
    justify-content: center;

    img {
      aspect-ratio: attr(width) / attr(height);
      height: 100%;
      width: auto;
      max-width: 100%;
    }
  }

  @media screen and (min-width: 600px) {
    padding-bottom: 132px;
  }
}
