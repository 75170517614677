.blue {
  color: #387efa;
  font-weight: 600;
}
.LoginLandlord {
  .push-top {
    position: relative;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
    margin-top: $headerHeight;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #f8f8f8;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-radius: 0 0 24px 24px;
    display: flex;
    margin-left: 0px !important;
    .topWrap {
      padding-top: 40px;
    }
    .left-col {
      margin-left: 0px;
      padding-left: 0px;

     
    }
    .dot {
      position: absolute;
      transform: matrix(0.91, 0.42, -0.42, 0.91, 0, 0);
      background: #fd784b;
      left: 20%;
      top: -23%;
      width: 180px;
      height: 180px;
      border-radius: 56px;
    }
  }
  @media screen and (min-width: 600px) {
    .push-top {
      padding-bottom: 48px;
      margin-bottom: 42px;
      padding-left: 56px !important;

      .dot {
        left: 33%;
        top: -36%;
        width: 250px;
        height: 250px;
        border-radius: 90px;
      }
      .topWrap {
        padding-top: 75px;
      }
      .container-fluid {
        padding-left: 32px;
      }
      .left-col {
        margin-left: 32px;
        margin-right: 24px;
        padding-right: 24px;
      }
      .right-col {
        top: -1rem;
        right: -1rem;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .push-top {
      padding-left: 88px !important;
      padding-bottom: 75px;

      .container-fluid {
        padding-left: 48px;
      }
      .left-col {
        margin-left: 40px;
        padding-left: 32px;
      }
      .dot {
        left: 29%;
        top: -74%;
        width: 462px;
        height: 462px;
        border-radius: 151px;
      }
    }
  }
}
.full-width {
  padding: 0;
  img {
    width: 100%;
    max-height: 760px;
  }
  overflow: hidden;
}
.icon-box {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon-text-box {
    display: block;
    border-radius: 24px;
    width: 28px;
    height: 32px;
    min-width: 28px;
    background-color: #d9e6fe;
    margin-bottom: 24px;
    position: relative;
    margin: auto 0;
    margin-right: 8px;

    span,
    Svg {
      position: absolute;
      left: 0px;
      right: 0px;
      margin: auto;
      text-align: center;
      top: 0px;
      bottom: 0px;
      color: $primaryColor;
      font-size: 12px;
      height: 12px;
    }
  }
}

.CustomerComment {
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 24px;

  .customerImg {
    margin-top: 0px;
    width: 100%;
    align-self: center;
  }
  .commentCol {
    flex-direction: column;
    .row {
      padding-left: 0;
    }
    .commentText {
      text-align: left;
      font-size: 24px;
      align-self: center;
      margin: 24px;
    }
    .customerName {
      font-size: 18px;
      font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
      font-weight: bold;
      margin-left: 24px;
      margin-bottom: 4px;
    }
    .customerDate {
      margin-left: 24px;
      font-size: 12px;
      font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
      font-weight: bold;
      letter-spacing: 0px;
      color: #6c757d;
    }
  }
  .hollowBox {
    position: absolute;
    left: 4%;
    top: 54%;
    transform: rotate(11deg);
    border: 6px solid #face3b;
    width: 75px;
    height: 75px;
    border-radius: 18px;
  }
  @media screen and (min-width: 600px) {
    padding: 48px 48px;
    margin-bottom: 48px;
    font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
    font-weight: bold;
    letter-spacing: -1.06px;

    .row {
      padding-left: 32px;
    }
    .customerImg {
      max-width: 460px;
    }
    .commentCol {
      .commentText {
        margin-left: 0px;
        font-size: 32px;
        line-height: 38px;
      }
      .customerDate,
      .customerName {
        margin-left: 0;
      }
    }
    .hollowBox {
      left: 51%;
      top: 24%;
      border: 8px solid #face3b;
      width: 108px;
      height: 108px;
      border-radius: 33px;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 96px 96px;
    margin-bottom: 100px;

    .row {
      padding-left: 56px;
    }
    .customerImg {
      max-width: 460px;
    }
    .commentCol {
      .commentText {
        font-size: 44px;
        line-height: 50px;
      }
    }
  }
}

.InfoSection {
  display: flex;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  padding: 48px 16px;
  max-width: 1760px !important;
  margin: auto;

  .infoBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .subtitle {
      font-size: 44px;
      font-weight: bold;
      font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
    }
    .infoText {
      font-size: 18px;
      font-weight: bold;
      font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
      letter-spacing: -0.14px;
    }
    .btnInfo {
      width: 100%;
      button {
        border-radius: 16px;
      }
    }
  }
  @media screen and (min-width: 600px) {
    .infoRow {
      margin: 48px;
      padding: 48px 0px 0px;
    }
    .infoBox {
      .btnInfo {
        margin-top: 32px !important;
        width: 138px;
      }
    }
  }
}

.WhyRentAptuno {
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 48px 16px;
  border-radius: 0;
  max-width: 1760px !important;
  margin: auto;

  .row {
    margin: 0px;
  }

  @media screen and (min-width: 600px) {
    padding: 48px;
    border-radius: 24px;
  }
  @media screen and (min-width: 992px) {
    padding: 118px;
  }
}

.OurClientsV2 {
  border-radius: 24px;
  width: 100%;
  margin: 0;
  background-color: #ebfaf1;
  min-height: 362px;
  height: fit-content;
  margin-top: 48px;
  padding: 24px;
  padding-bottom: 1rem;

  .hollowBox {
    position: absolute;
    top: 96px;
    right: -3px;
    transform: matrix(0.96, -0.29, 0.29, 0.96, 0, 0);
    border: 6px solid #3cd278;
    width: 55px;
    height: 55px;
    border-radius: 12px;
  }

  .carousel {
    height: 100%;

    .carousel-control-next,
    .carousel-control-prev {
      display: none;
    }

    .carousel-indicators {
      margin-bottom: 0;
      display: flex;
      align-items: center;

      li {
        height: 4px;
        width: 4px;
        line-height: 4px;
        background: #cdcdcd;
        border-radius: 5px;
        border: 1px;
      }
      .active {
        width: 8px;
        height: 8px;
        line-height: 8px;
        background: #787878;
      }
    }

    .carousel-inner {
      .commentGalleryItem {
        .headerComment {
          height: 40px !important;
          display: flex;
          flex-direction: row;
          margin-left: 1rem;
          margin-bottom: 24px;

          .clientName {
            font-size: 16px;
            font-weight: bold;
            font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
            margin-bottom: 0;
          }
          .clientDate {
            font-size: 16px;
            font-weight: 400;
            font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
            margin-bottom: 0;
            letter-spacing: -0.13px;
            color: #6c757d;
          }
          .clientImg {
            height: 40px !important;
            width: 40px;
            overflow: hidden;
            border-radius: 25px;
            display: block;
            margin-right: 1rem;

            img {
              width: 40px;
              height: 40px;
            }
          }
        }
        .commentText {
          color: black;
          font-size: 18px;
          font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
          font-weight: bold;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    margin-top: 0px;
    padding: 40px;
    padding-bottom: 32px;

    .hollowBox {
      position: absolute;
      top: 48px;
      right: -6px;
      transform: matrix(0.96, -0.29, 0.29, 0.96, 0, 0);
      border: 8px solid #3cd278;
      width: 110px;
      height: 110px;
      border-radius: 33px;
    }
    .carousel-inner {
      .commentGalleryItem {
        .commentText {
          font-size: 24px !important;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    padding: 80px;
  }
}

.summaryText {
  font-size: 12px;
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 400;
  letter-spacing: 0px;
  color: #6c757d;
  margin: auto;
}

.resultsLink {
  text-align: center;
  font-size: 12px;
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 400;
  letter-spacing: 0px;
  color: #787878;
  margin: auto;
  a {
    color: #0055fb;
  }
}

.NewsletterForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
  border-radius: 24px;
  min-height: 291px;
  padding: 24px;
  .customInputLabel {
    max-width: 100%;
  }
  .customInput,
  .customButton button {
    border-radius: 20px;
  }
  .customButton {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .invalid-feedback {
    position: absolute;
  }

  .title {
    font-size: 24px;
    font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
    font-weight: bold;
    letter-spacing: -0.58px;
    color: #ffffff;
  }
  .description {
    font-size: 18px;
    font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
    font-weight: 400;
    letter-spacing: -0.14px;
    color: #dee2e6;
  }
  .left-col,
  .right-col {
    padding: 0 1rem;
  }

  @media screen and (min-width: 600px) {
    padding: 40px;
  }
  @media screen and (min-width: 768px) {
    .left-col {
      padding: 0;
    }
    .right-col {
      padding-right: 0;
      padding-left: 16px;
    }
  }
}
.SuscriptionAndReferWrap {
  padding: 24px;

  @media screen and (min-width: 600px) {
    padding: 56px;
  }
}

.align-top {
  align-items: flex-start !important;
  vertical-align: top !important;
}
.align-left {
  display: flex;
  justify-content: flex-end !important;
}
.align-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
