[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye:before {
  content: "\e925";
}
.icon-calendar_icon:before {
  content: "\e92f";
}
.icon-zoom_in_map_icon:before {
  content: "\e92d";
}
.icon-zoom_out_map_icon:before {
  content: "\e92e";
}
.icon-menu_icon:before {
  content: "\e92c";
}
.icon-filter_fill:before {
  content: "\e92b";
}
.icon-icon_calendar:before {
  content: "\e92a";
}
.icon-eye_icon:before {
  content: "\e929";
}
.icon-share2_icon:before {
  content: "\e926";
}
.icon-whatsapp_frame_icon:before {
  content: "\e927";
}
.icon-copy_frame_icon:before {
  content: "\e928";
}
.icon-time_icon:before {
  content: "\e921";
}
.icon-image_icon:before {
  content: "\e923";
}
.icon-checklist_icon:before {
  content: "\e924";
}
.icon-succes_bg_icon:before {
  content: "\e920";
  color: #000;
}
.icon-validando_loader:before {
  content: "\e922";
}
.icon-close_icon:before {
  content: "\e91f";
}
.icon-wa_icon:before {
  content: "\e91e";
}
.icon-pet_icon:before {
  content: "\e90b";
}
.icon-house_icon:before {
  content: "\e914";
}
.icon-expand_icon:before {
  content: "\e915";
}
.icon-cellphone_icon:before {
  content: "\e916";
}
.icon-aptuno_logo_2colors .path1:before {
  content: "\e917";
  color: rgb(0, 85, 251);
}
.icon-aptuno_logo_2colors .path2:before {
  content: "\e918";
  margin-left: -4.3046875em;
  color: rgb(0, 0, 0);
}
.icon-share_icon-1:before {
  content: "\e919";
  color: #6c757d;
}
.icon-Instagram:before {
  content: "\e91a";
  color: #6c757d;
}
.icon-Symbols:before {
  content: "\e91b";
  color: #6c757d;
}
.icon-Symbols-1:before {
  content: "\e91c";
  color: #6c757d;
}
.icon-Symbols-2:before {
  content: "\e91d";
  color: #6c757d;
}
.icon-menu_mobile_icon:before {
  content: "\e913";
}
.icon-share_icon:before {
  content: "\e910";
}
.icon-user_icon:before {
  content: "\e911";
}
.icon-car_icon:before {
  content: "\e912";
}
.icon-filter_icon:before {
  content: "\e900";
}
.icon-triangle_icon:before {
  content: "\e901";
}
.icon-squarem_icon:before {
  content: "\e902";
}
.icon-show_3_icon:before {
  content: "\e903";
}
.icon-show_2_1_icon:before {
  content: "\e904";
}
.icon-show_1_2_icon:before {
  content: "\e905";
}
.icon-search_icon:before {
  content: "\e906";
}
.icon-line_arrow_icon:before {
  content: "\e907";
}
.icon-hidden_eye_icon:before {
  content: "\e908";
}
.icon-heart_icon_selected:before {
  content: "\e909";
}
.icon-heart_icon:before {
  content: "\e90a";
}
.icon-dollar_icon:before {
  content: "\e90c";
}
.icon-bed_icon:before {
  content: "\e90d";
}
.icon-bath_icon:before {
  content: "\e90e";
}
.icon-arrow_down_icon:before {
  content: "\e90f";
}

.icon-line_arrow_icon_reverse {
  transform: rotate(180deg);
  &:before {
    content: "\e907";
  }
}

/* ----- */

.icon-area:before {
  content: "\e902";
}
.icon-banos:before,
.icon-nro-banos:before {
  content: "\e90e";
}

.icon-dormitorios:before,
.icon-nro-cuartos:before {
  content: "\e90d";
}
