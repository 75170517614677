@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loginBucket {
  [class^='state-'],
  [class*=' state-'] {
    transition: all 0.2s ease;
    max-height: 0px;
    overflow: hidden;
    &.active {
      max-height: 500px;
    }
  }
  .info-text {
    margin: auto;
    margin-top: 19px;
    font-weight: 300;
    color: #6c757d;
  }

  .phoneForm {
    align-items: center;
    .form-icon.icon-cellphone_icon {
      color: $primaryColor;
    }
    .customInput {
      width: calc(100% - 100px);
      input {
        height: 54px;
      }
    }
    .customInputSelect {
      width: 80px;
      border-radius: 8px;
      border-radius: 8px;
      border: 1px solid #DEE2E6;
      height: 48px;
      .content {
        position: relative;
        top: -2px;
        .form-select-icon {
          top: 18px;
        }
      }
    }
  }
  .sms-btn {
    margin-top: 98px;
  }
  .whatsapp-btn {
    button {
      .form-width-icon {
        padding-left: 0px !important;
        top: -2px;
      }
      .form-icon {
        font-size: 18px;
        position: relative;
        left: -16px;
        top: 2px;
        &:before {
          transition: all 0.2s ease;
          color: $primaryColor;
        }
        &.icon-validando_loader {
          position: absolute;
        }
      }
      &:hover {
        .form-icon {
          &:before {
            color: white;
          }
        }
      }
    }
  }
  .footer {
    p {
      text-align: center;
      margin-top: 30px;
      border-top: 1px solid $linesColor;
      padding-top: 30px;
    }
  }

  .footer {
    .loader {
      position: absolute;
      margin-top: 2px;
      margin-left: 6px;
      animation: rotate 3s infinite linear;
      span {
        position: absolute;
        left: 0px;
        transform: rotate(130deg);
        opacity: 0.2;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .firstText {
      padding: 10px 60px;
    }
  }
}

.codeInputField {
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 500px) {
    .customInput {
      width: 40px !important;
      border-radius: 8px !important;
      input {
        height: 40px !important;
      }
      height: 100% !important;
    }
  }
  .customInput {
    width: 64px;
    border-radius: 16px;
    cursor: pointer;
    input {
      height: 64px;
      font-size: 24px;
      text-align: center;
      &:hover,
      &:focus {
        border: 1px solid $primaryColor;
        border-radius: 16px;
        @media screen and (max-width: 500px) {
          & {
            border-radius: 8px;
          }
        }
      }
    }
  }
}
