html {
  body {
    font-family: $font;
    margin: 0;
    font-family: $font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.App {
  min-height: 100vh;
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.container-fluid.fluid-98 {
  width: 98% !important;
}

.container,
.container-fluid {
  &.push-top {
    margin-top: $headerHeight;
  }
  &.push-top-header {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
}

.c-black {
  color: black;
}
.c-white {
  color: white;
}
.c-grey {
  color: #6c757d;
}
.c-orange {
  color: $likeColor;
}
.c-primary {
  color: $primaryColor;
}
.c-primary2 {
  color: $primaryColor;
}
.c-secondary {
  color: $secondaryColor;
}
.c-green {
  color: $greenColor;
}
.c-text {
  color: $textColor;
}
.c-text2 {
  color: $textColor2;
}
.bg-primary {
  background-color: $primaryColor;
}
.bg-secondary {
  background-color: $secondaryColor;
}
.c-hover-primary:hover {
  color: $primaryColor;
}
.c-violet {
  color: #6622f2;
}
.c-deep-rose {
  color: #d250e6;
}

.cursor-pointer {
  cursor: pointer;
}
.l-0 {
  left: 0 !important;
}
.r-0 {
  right: 0 !important;
}
.pt-03 {
  padding-top: 3px !important;
}
.pb-03 {
  padding-bottom: 3px !important;
}
.t-1 {
  top: 1px;
}
.pt-exact-72 {
  padding-top: 72px;
}

@for $i from 1 through 90 {
  .font-#{$i},
  .f-#{$i} {
    font-size: #{$i}px;
    line-height: #{$i}px;
  }
}

@for $i from 1 through 90 {
  .font-md-#{$i},
  .f-md-#{$i} {
    @media screen and (min-width: 768px) {
      font-size: #{$i}px;
      line-height: #{$i}px;
    }
  }
}

@for $i from 1 through 90 {
  .lh-#{$i} {
    line-height: #{$i}px !important;
  }
}

@for $i from 1 through 90 {
  .z-#{$i} {
    z-index: #{$i};
  }
}

@for $i from 1 through 40 {
  .br-#{$i} {
    border-radius: #{$i}px !important;
  }
}

.min-height-100vh {
  min-height: 100vh;
}
.font-satoshi {
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
}
.font-satoshi-bold {
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: bold !important;
}
.font-satoshi-black {
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 600 !important;
}
.font-satoshi-medium {
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 500 !important;
}
.font-satoshi-book {
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 400 !important;
}

.flex-column {
  flex-direction: column;
}
.jc-center {
  justify-content: center;
}
.jc-space-around {
  justify-content: space-around;
}
.jc-space-between {
  justify-content: space-between;
}
.jc-start {
  justify-content: start;
}
.jc-flex-end {
  justify-content: flex-end;
}
.ai-center {
  align-items: center;
}
.ps__thumb-y {
  background-color: $primaryColor !important;
  max-width: 12px;
}
.Shimmer-shimmer-0-1-1 {
  background-size: initial !important;
}

.Shimmer-shimmer-0-1-1 {
  display: inline-block !important;
  animation: shimmerAnim 2s infinite ease !important;
  background: white !important;
  background-image: linear-gradient(to right, white 30%, #f7f7f7 60%, white 30%) !important;
  background-repeat: no-repeat !important;
}

.customListGroup {
  .list-group-item {
    display: flex;
    height: 24px;
    align-items: center;
    text-decoration: none !important;
    * {
      text-decoration: none !important;
    }
    border: none;
    background: none;
    transition: all 0.2s ease;
    p {
      margin-bottom: 0px;
    }
    span {
      display: flex;
      &.rotated {
        transform: rotate(180deg);
      }
      position: relative;
      margin-left: 4px;
      top: 0px;
    }
  }
  &.customListGroup-link {
    .list-group-item:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.globalDefault-icon {
  transition: all 0.2s ease;
  &:before {
    transition: all 0.2s ease;
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background-color: $primaryColor;
    margin: auto;
    border-radius: 24px;
  }
}
.slick-slider {
  .slick-arrow {
    transition: all 0.2s ease;
    border-radius: 50%;
    background: white;
    border: 0.2 solid #6c757d24;
    box-shadow: 0px 10px 15px #6c757d24;

    &:before {
      color: #6c757d;
      font-family: 'icomoon' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 12px;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
    }
    opacity: 0;
    padding: 16px;

    &:hover {
      opacity: 1 !important;
      box-shadow: 0px 15px 25px #6c757d40;
      transition: all 0.3s 0.1s ease;
    }
  }
  &:hover {
    .slick-arrow {
      opacity: 0.7;
    }
  }
}
.slick-next {
  right: 8px !important;
  z-index: 7;

  &:before {
    left: 50%;
    top: 50%;
    content: '\e907' !important;
    transform: translate(-50%, -50%) !important;
  }
}

.slick-prev {
  left: 8px !important;
  z-index: 2;
  &:before {
    left: 50%;
    top: 50%;
    content: '\e907' !important;
    transform: rotate(180deg) translate(50%, 50%) !important;
  }
}

.slick-dots {
  bottom: 10px !important;
  z-index: 2;
  li {
    margin: 0px !important;
    width: 12px !important;
  }
  .slick-active {
    button:before {
      font-size: 10px !important;
      color: white !important;
      opacity: 1;
      line-height: 20px;
    }
  }
  button:before {
    transition: all 0.2se ease;
    width: 12px;
    font-size: 7px !important;
    color: white !important;
    opacity: 0.8;
  }
}

* {
  font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
  font-weight: 400;
}

.w-90 {
  width: 90% !important;
}

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}

.gm-bundled-control {
  margin-bottom: 30px !important;
  margin-right: 15px !important;
  .gmnoprint {
    & > div {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      button {
        background-color: white !important;
        border-radius: 100% !important;
        &:nth-child(1) {
          margin-bottom: 10px !important;
        }
      }
    }
  }
}
.gm-fullscreen-control {
  display: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.comun-list {
  list-style: none;
  padding-left: 0px;
}

.border-bottom-1 {
  border-bottom: 1px solid #e0e0e0;
}

.p-icon {
  span[class^='icon-'] {
    position: relative;
    top: 2px;
    left: -3px;
    margin-right: 2px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  span.icon-validando_loader {
    left: 0px !important;
    animation: rotate 3s infinite linear;
    color: white;
  }
}

.formError {
  margin: 15px;
  color: red;
}
.text-decoration-none {
  text-decoration: none;
}

.centered {
  position: relative;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  display: table;
}

.ls-011 {
  letter-spacing: 0.11px;
}
.ls-029 {
  letter-spacing: 0.29px;
}
.ls-l-146 {
  letter-spacing: -1.46px;
}
.ls-l-134 {
  letter-spacing: -1.34px;
}
.ls-l-106 {
  letter-spacing: -1.06px;
}
.ls-l-088 {
  letter-spacing: -0.88px;
}
.ls-l-011 {
  letter-spacing: -0.11px;
}
.ls-l-072 {
  letter-spacing: -0.72px;
}
.ls-l-058 {
  letter-spacing: -0.58px;
}
.ls-l-053 {
  letter-spacing: -0.53px;
}
.ls-l-044 {
  letter-spacing: -0.44px;
}
.ls-l-013 {
  letter-spacing: -0.13px;
}
.ls-l-014 {
  letter-spacing: -0.14px;
}
.ls-l-019 {
  letter-spacing: -0.19px;
}

.ls-l-26 {
  letter-spacing: -26px;
}
.ls-l-24 {
  letter-spacing: -24px;
}
.ls-l-8 {
  letter-spacing: -8px;
}

@media screen and (min-width: 1230px) {
  .container {
    max-width: 1200px !important;
  }
}

/* default zoom map  */
.gmnoprint {
  display: none;
}

/* custom zoom map  */
.followMap {
  transition: all 0.2s ease;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  z-index: 3;
  background: white;
  border-radius: 12px;
  height: 40px;
  padding: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  left: initial;
  width: fit-content;
  margin: auto;
  top: 15px;
  opacity: 0.9;
  cursor: pointer;

  & label {
    font-size: 12px !important;
    margin: auto;

    span {
      line-height: 16px;
    }
  }
  &:hover {
    opacity: 1;
    box-shadow: 0px 10px 15px #6c757d24;
    transition: all 0.3s 0.1s ease;
  }
  &.active {
    background-color: $primaryColor;
    color: white;
  }
}
.zoom {
  position: absolute;
  right: 0px;
  z-index: 8;
  background: white;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  text-align: center;
  margin: auto;
  padding-top: 2px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0px 0px 8px #6c757d0f;
  &:before {
    margin: auto;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
  &.zoom-more {
    right: 30px;
    bottom: 84px;
  }
  &.zoom-less {
    right: 30px;
    bottom: 30px;
  }
}

.mt-64 {
  margin-top: 64px !important;
}

/* Chrome, Safari, Edge, Opera */
input.noIncrement::-webkit-outer-spin-button,
input.noIncrement::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.noIncrement[type='number'] {
  -moz-appearance: textfield;
}

.gm-style-iw-t {
  display: none;
}

.blueDot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 100px;
  background: $primaryColor;
  margin-left: 5px;
  top: -1px;
  position: relative;
  box-shadow: 0px 0px 2px $primaryColor;
}

.data-boxes {
  @media screen and (min-width: 992px) {
    & > div {
      justify-content: space-around;
    }
  }
  @media screen and (max-width: 992px) {
    & > div {
      flex-direction: column !important;
    }
  }
}
.DataBox > div {
  min-width: 160px;
}

.no-scroll {
  overflow: hidden !important;
}

.c-pointer {
  cursor: pointer;
}
.react-share__ShareButton {
  outline: none !important;
  box-shadow: none !important;
}
.no-underline {
  text-decoration: none !important;
}
.underline {
  text-decoration: underline !important;
}
.color-initial {
  color: initial !important;
}

.like-link {
  cursor: pointer !important;
  text-decoration: none !important;
  &:hover {
    color: $primaryColor;
  }
}

.bkg-grey {
  background: $lightGreyBg;
}

.aptunoTypeform {
  margin-top: 70px;
}

.aptuno-cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aptuno-marker {
  background: #1978c8;
  border: none;
}

.aptuno-marker img {
  width: 25px;
}

.gap-16 {
  gap: 16px;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}