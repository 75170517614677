.faq-banner {
  .head {
    text-align: center;
    margin: 60px auto; 
    p {
      color: $textColor2;
    }
  }
  .faq-content {
    display: flex;
    .categories {
      margin-bottom: 15px;
      & > .title {
        color: $textColor2;
        font-size: 16px;
      }
      ul {
        padding-left: 0px;
        list-style: none;
        li {
          transition: all 0.2s ease;
          font-size: 24px;
          line-height: 26px;
          font-weight: bold;
          margin-bottom: 15px;
          position: relative;
          cursor: pointer;
          &:before {
            transition: all 0.2s ease;
            content: "";
            position: absolute;
            margin-left: 0px;
            margin-right: 0px;
            left: 0px;
            height: 100%;
            width: 0px;
            border-radius: 4px;
            background-color: transparent;
          }
          &.selected {
            padding-left: 10px;
            &:before {
              width: 5px;
              background-color: $primaryColor;
            }
          }
        }
      }
      width: 150px;
    }
    .questions {
      ul {
        padding-left: 0px;
        list-style: none;
        li {
          & > h3.title  {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            font-size: 14px;
            background-color: $secondaryColor;
            padding: 10px 16px;
            border-radius: 16px;
            color: $textColor2;
            &:after {
              content: "+";
            }
            &.show {
              &:after {
                content: "-";
              }
              & + .answer {
                height: auto;
                max-height: 1000px;
              }
            }
          }
          .answer {
            transition: all 1.5s ease;
            height: 0px;
            max-height: 0px;
            overflow: hidden;
            padding: 0px 16px;
          }
        }
      }
    }
  }
}
