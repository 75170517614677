.ReminderAppointmentModal {
  .body {
    background-color: #F8F8F8;
    border-radius: 26px;
  }
  .content {
    min-height: fit-content;
  }
  .ReminderAppointment {
    display: flex;
    justify-content: center;
    align-items: center;

    .popupImg {
      border-radius: 16px;
    }
  }
  .propertyContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 280px;
    }
    .contentText {
      color: #FB8D5D;
      font-size: 24px;
      font-weight: 400;
    }
  }
}