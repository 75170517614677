.pac-container {
  border-radius: 8px;

  & .pac-item-query {
    font-family: 'Satoshi',-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
    font-weight: 400;
    font-size: 1rem;
  }
}
.pac-container:after{display:none !important;}
