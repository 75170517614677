.OurClients {
  background-color: #F8F8F8;
  border-radius: 24px;
  padding-bottom: 40px;
  margin-bottom: 56px;

  .slick-prev, .slick-next{
    opacity: 1 !important;
    &:before {
      color: black;
    } 
  }
  .OurClients-slider {
    margin-bottom: 40px;
    .text { 
      margin-top: 40px;
      p { max-width: 420px; margin: auto;}
      margin-bottom: 24px;
    }
  }
  .OurClients-img-slider { 
    padding-left: 60px;
    padding-right: 60px;
    width: 350px;
    max-width: 90vw;
    text-align: center;
    margin: auto;
    .slick-slide {
      .comment-gallery-item-pic {
        transform: scale(0.6);
        transition:  all 0.2s ease;
        border: 1px solid transparent;
        padding: 4px;
        border-radius: 100%;
        width: 60px !important;
        height: 60px;
      }
      &.slick-current {
        .comment-gallery-item-pic {
          transform: scale(1);
          border: 2px solid orange;
        }
      }
    } 

  }
  .comment-gallery-item-pic {
    overflow: hidden;
    * { outline: none; }
    .comment-bg-img {
      margin: auto;
      width: 88%;
      height: 88%;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      outline: none;
    }
  }
  .comment-gallery-item {
    * { outline: none; }
  }
}