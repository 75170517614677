.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left: $medium;
    padding-right: $medium;
}

.m-1 { margin: $small !important; }
.m-2 { margin: $medium !important; }
.m-3 { margin: $large !important; }
.m-4 { margin: $x-large !important; }
.mt-1 { margin-top: $small !important; }
.mt-2 { margin-top: $medium !important; }
.mt-3 { margin-top: $large !important; }
.mt-4 { margin-top: $x-large !important; }
.mb-1 { margin-bottom: $small !important; }
.mb-2 { margin-bottom: $medium !important; }
.mb-3 { margin-bottom: $large !important; }
.mb-4 { margin-bottom: $x-large !important; }
.ml-1 { margin-left: $small !important; }
.ml-2 { margin-left: $medium !important; }
.ml-3 { margin-left: $large !important; }
.ml-4 { margin-left: $x-large !important; }
.mr-1 { margin-right: $small !important; }
.mr-2 { margin-right: $medium !important; }
.mr-3 { margin-right: $large !important; }
.mr-4 { margin-right: $x-large !important; }

.p-1 { padding: $small !important; }
.p-2 { padding: $medium !important; }
.p-3 { padding: $large !important; }
.p-4 { padding: $x-large !important; }
.pt-1 { padding-top: $small !important; }
.pt-2 { padding-top: $medium !important; }
.pt-3 { padding-top: $large !important; }
.pt-4 { padding-top: $x-large !important; }
.pb-1 { padding-bottom: $small !important; }
.pb-2 { padding-bottom: $medium !important; }
.pb-3 { padding-bottom: $large !important; }
.pb-4 { padding-bottom: $x-large !important; }
.pl-1 { padding-left: $small !important; }
.pl-2 { padding-left: $medium !important; }
.pl-3 { padding-left: $large !important; }
.pl-4 { padding-left: $x-large !important; }
.pr-1 { padding-right: $small !important; }
.pr-2 { padding-right: $medium !important; }
.pr-3 { padding-right: $large !important; }
.pr-4 { padding-right: $x-large !important; }
