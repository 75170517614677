.PropertyDetails {
  .stickyHelperClass { 
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media screen and (max-width: 768px) {
    .stickyHelperClass {
      & > div > div {
        min-height: auto;
      }
    }
  }

  .title {
    letter-spacing: -0.19px;
  }
  .price {
    letter-spacing: -0.19px;
  }
  .miniTitle {
    letter-spacing: -0.11px;
  }
  @media screen and (max-width: 768px) {
    .title {
      letter-spacing: -0.53px;
    }
    .price {
      letter-spacing: -0.53px;
    }
    .miniTitle {
      letter-spacing: -0.14px;
    }
  }

  .innerCoolChartText {
    width: 136px;
    height: 32px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    text-align: center;
    position: absolute;
    transform: scale(0.9);
    span {
      display: block;
    }
  }
  @media screen and (min-width: 768px) {
    .withBorderLeft {
      border-left: 1px solid #e0e0e0;
    }
  }
  .CoolChart {
    width: 136px;
    height: 136px;
  }

  /* deprecated-toggle-more-content */
  &.with-details-bar {
    margin-bottom: 120px;
  }
  .label-in-price {
    position: relative;
    top: -4px;
  }
  .stickyDeatilsTab {
    padding-top: 80px;
  }
  .details-multi-tabs {
    .navbar-item {
      display: flex;
    }
  }
  .split-bucket-text {
    display: flex;
    max-width: 100%;
    flex-direction: row;

    .left {
      width: 200px;
      display: flex;
    }
    .right {
      width: 100%;
      display: flex;
    }
    @media screen and (max-width: 879px) {
      display: block !important;
      .left,
      .right {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  .label-buckets {
    .labelBucket {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

  .topBar {
    padding-top: 10px;
    padding-bottom: 10px;
    div:nth-child(2) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .labelBucket {
      height: auto;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .details-item-mapa {
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    .MapContainer {
      height: 400px !important;
    }
  }
  .lazyframe {
    border-radius: 1.5rem;
    overflow: hidden;
  }
}
  /* deprecated-toggle-more-content */
  .miniMultiTextComponent {
    .iconBucket {
      span:nth-child(1) {
        opacity: 1 !important;
      }
      span:nth-child(2) {
        opacity: 0 !important;
      }
    }
    @media screen and (max-width: 768px) {
      & > p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 114px;
        span:nth-child(1) {
          width: 40px;
          height: 40px;
          span {
            margin: 14px 12px;
          }
        }
        span:nth-child(2) {
          text-align: center;
          margin-top: 10px;
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    }
  }
.miniMultiTextComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(.active) {
    .iconBucket {
      span:nth-child(1) {
        opacity: 0;
      }
      span:nth-child(2) {
        opacity: 1;
      }
    }
  }
  &:hover {
    .iconBucket {
      background-color: $greyBg;

      span:nth-child(1) {
        opacity: 0;
      }
      span:nth-child(2) {
        opacity: 1;
        color: white;
      }
    }
  }
  p {
    font-weight: 300;
    color: $textColor2;
    display: flex;
    align-items: center;
  }
  .iconBucket {
    transition: all 0.2s ease;
    position: relative;
    background: $primaryColor2;
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 100%;
    span:nth-child(2) {
      opacity: 0;
      font-size: 14px;
      left: 1px;
    }
    span {
      transition: all 0.2s ease;
      top: -2px;
      position: absolute;
      margin: 10px 8px;
      font-size: 16px !important;
    }
  }
  span:first-child {
    /*
    background: $primaryColor2;
    padding: 8px 6px;
    border-radius: 100%;
    */
  }
}

.MiniDetails {
  display: flex;
  align-items: center;
  .image {
    height: 48px;
    width: 48px;
    border-radius: 10px;
    position: relative;
    background-color: grey;
    & > * {
      border-radius: 10px;
    }
  }
}

.DataBox {
  .data p {
    margin-bottom: 4px !important;
  }
  @media screen and (max-width: 400px) {
    .data p {
      font-size: 8px;
    }
  }
}
.propertyGalleryBox > div {
  min-height: fit-content !important;
}