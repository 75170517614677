$imageInGalleryHeight: 180px;
.cardbox {
  cursor: pointer;
  .results-gallery {
    .slick-list {
      max-height: 200px;
      .sliderImage {
        max-width: 100%;
      }
    }
  }
  &:hover {
    box-shadow: 0px 0px 8px #6c757d0f;
  }
  transition: all 0.2s ease;

  &.miniCardBox {
    z-index: 9999999;
    position: absolute;
    width: 310px !important;
    margin-left: -155px;
    margin-top: -1px;
    min-height: 153px !important;
    transform: scale(0.8);
  }
  margin-bottom: 32px;
  background-color: white;
  width: 100%;
  min-height: 375px;
  overflow: hidden;
  border-radius: 16px;

  .descripcionBox {
    width: calc(100% - 32px);
    margin-top: 4px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    .title {
      p {
        letter-spacing: -0.14px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 30px;
        margin-bottom: 0px;
        overflow: hidden;
        white-space: nowrap;
      }
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .desc {
      display: block !important;
      p {
        &:not(:last-child):after {
          margin: 0px 5px;
          content: " ";
        }
        color: $textColor;
        display: inline-block !important;
        white-space: nowrap;
      }
    }
    .priceAndButtons {
      margin-top: 7px;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      .price {
        align-items: flex-end;
        color: $primaryColor;
        span {
          font-size: 18px;
        }
      }
    }
    .tagsAndButtons {
      display: flex;
      justify-content: space-between;
      .tags {
        ul {
          padding: 0px;
          list-style: none;
          margin: 0px;
          color: $primaryColor;
          li.label {
            display: inline;
            /* &:not(:last-child):after { margin: 0px 5px; content: "•"; } */
            color: white;
            background: #0055fb;
            padding: 4px 4px 2px;
            line-height: 10px;
            border-radius: 4px;
            margin-top: 3px;
            position: relative;
          }
          li {
            display: inline;
            margin-right: 4px;
          }
        }
      }
      .buttons {
        display: flex;
      }
    }
    .button {
      width: 32px;
      height: 32px;
      position: relative;
      cursor: pointer;
      transform: all 0.2s ease;
      border-radius: 100%;
      font-size: 14px;
      color: $textColor;
      background-color: $linesColor;
      &:before {
        position: absolute;
        left: 1px;
        top: -4px;
        right: 0px;
        margin: auto;
        text-align: center;
        bottom: 0px;
        height: 10px;
        width: 15px;
      }
      &:hover,
      &.active {
        &:nth-child(1) {
          background-color: #d9e6fe;
          color: $primaryColor;
        }
        &:nth-child(2) {
          background-color: #ffeae2;
          color: #fd8d5d;
          &:before {
            content: "\e909";
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    & {
      border-radius: 24px;
    }
  }
}
