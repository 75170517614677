.Blog {
  .blog-category {
    color: $primaryColor;
    text-align: center;
    font-size: 14px;
    margin-top: 85px;
  }
  .blog-title {
    font-size: 44px;
    line-height: 50px;
    letter-spacing: -1.06px;
    color: #000000;
    opacity: 1;
    font-weight: bold;
    margin: 0px auto 35px auto;
    max-width: 450px;
    text-align: center;
  }
  .social {
    .social-box {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  .author {
    margin-bottom: 30px;
    text-align: center;
    .name {
      font-size: 16px;
      margin-bottom: 0px;
    }
    .date {
      margin-bottom: 0px;
      color: grey;
      font-size: 14px;
      margin-top: -5px;
    }
  }
  .footerAuthor {
    display: flex;
    width: 100%;
    border-top: 1px solid lightgray;
    padding-top: 15px;
    justify-content: space-between;
    .info {
      display: flex;
      align-items: center;
      .image {
        margin-right: 10px;
        border-radius: 100%;
        width: 80px;
        height: 80px;
        background-color: lightgray;
        background-image: url('/images/aptuno-logo-white.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 26px;
      }
      .name {
        font-size: 16px;
        margin-bottom: 0px;
      }
      .date {
        margin-bottom: 0px;
        color: grey;
        font-size: 14px;
        margin-top: -5px;
      }
    }
    .social {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0px;
      }
    }
  }
  .article-body {
    margin-top: 65px;
  }
  .article-box {
    position: relative;
    &:before {
      transition: all 0.2s ease;
      content: "";
      width: 100%;
      height: 100%;
      background-color: #21cfbc33;
      position: absolute;
      overflow: hidden;
      border-radius: 24px;
      transform: rotate3d(1, 1, 1, 0deg);
    }
    &:hover {
      &:before {
        transform: rotate3d(1, 1, 1, -5deg);
      }
    }
    text-decoration: none !important;
    min-height: 400px;
    width: 100%;
    background-color: #a7a7a7;
    border-radius: 24px;

    display: flex;
    .content {
      overflow: hidden;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0px;
      z-index: 2;
      align-items: flex-end;
      display: flex;
      border-radius: 24px;
      .bkg-image {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      .text {
        z-index: 3;
        padding-left: 16px;
        padding-right: 16px;
        color: white;
        .when {
          font-size: 12px;
          letter-spacing: 0px;
          margin-bottom: 16px;
        }
        .title {
          font-size: 24px;
          line-height: 36px;
          letter-spacing: -0.58px;
          margin-bottom: 24px;
        }
      }
    }
  }
  .image1 {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    .bkg-image {
      border-radius: 16px 16px 0px 0px;
      background-position: center;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .image2,
  .image3 {
    margin: auto;
    width: 80%;
    height: 350px;
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 30px;
    .bkg-image {
      border-radius: 16px;
      background-position: center;
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .content {
    width: 80%;
    margin: auto;
    background-color: white;
    padding: 16px;
    blockquote {
      padding-left: 68px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 8px;
        border-radius: 10px;
        height: 100%;
        background-color: black;
        left: 0px;
      }
    }
  }
}

.SolutionThumb {
  font-family: 'Satoshi',-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif !important;
  width: 100%;
  .with-shadow {
    &:after {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      background-color: black;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  .contentA {
    border-radius: 30px;
    width: 100%;
    position: relative;
    .bkg-image {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
    }
  }
  .tag {
    color: $primaryColor;
    font-size: 14px;
  }

  &.BIG {
    .contentA {
      border-radius: 30px;
      height: 480px;
      .bkg-image {
        &:before,
        &:after {
          border-radius: 30px;
        }
        border-radius: 30px;
      }
    }
    .contentB {
      padding-top: 16px;
    }
    .tag {
      margin-top: 12px;
      margin-bottom: 12px;
      color: $primaryColor;
      font-size: 16px;
    }
    .title {
      line-height: 50px;
      font-size: 44px;
      color: black;
    }
  }
  &.SMALL {
    padding-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .contentA {
      border-radius: 16px;
      width: 160px;
      min-width: 160px;
      height: 160px;
      .bkg-image {
        &:before,
        &:after {
          border-radius: 16px;
        }
        border-radius: 16px;
      }
    }
    .contentB {
      margin-left: 16px;
    }
    .title {
      line-height: 28px;
      font-size: 24px;
      color: black;
    }
  }

  &.MEDIUM {
    .contentA {
      border-radius: 30px;
      height: 320px;
      .bkg-image {
        &:before,
        &:after {
          border-radius: 30px;
        }
        border-radius: 30px;
      }
    }
    .contentB {
      padding-top: 16px;
    }
    .tag {
      margin-top: 12px;
      margin-bottom: 12px;
      color: $primaryColor;
      font-size: 16px;
    }
    .title {
      line-height: 28px;
      font-size: 24px;
      color: black;
    }
  }
  &.innerContent {
    position: relative;
    &:hover {
      .contentA {
        &:before {
          transform: translate(10px, 10px) rotate(3deg);
        }
      }
    }
    .contentA {
      cursor: pointer;
      height: 350px;
      &:before {
        transition: all 0.2s ease;
        border-radius: 30px;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: lightsteelblue;
        opacity: 1;
      }
      &:after {
        border-radius: 30px;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.4;
      }
    }
    .contentB {
      cursor: pointer;
      padding: 12px;
      position: absolute;
      bottom: 0px;
      * {
        color: white !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Blog {
    .content {
      width: 100%;
    }
  }
  .SolutionThumb {
    margin-bottom: 8px;
    margin-top: 8px;
    &.BIG {
      .contentA {
        height: 280px;
      }
    }
    &.SMALL {
      .title {
        font-size: 18px;
        line-height: 22px;
      }
      .contentA {
        width: 100px;
        min-width: 100px;
        height: 100px;
      }
    }
    .contentB {
      margin-bottom: 12px;
    }
  }
}

.guides {
  & p > b {
    font-size: 20px;
    line-height: 26px;
  }
  & h2,h3 {
    padding: 24px 0 16px 0;
  }
}
