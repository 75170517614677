
.loginRow{
  display: flex;
  align-items: center;

  .customInputSelect,
  .flagSelector,
  .customInput,
  .customInput input {
    height: 48px !important;
  }
  .flagSelector .content {
    padding: 0 8px;
  }
  .customInput input {
    padding-top: 0;
  }
  .loginBtn {
    button {
      border-radius: 16px;
      width: 128px;
      height: 48px;

    }
  }
  .otpText {
    margin-top: 1rem !important;
  }
}
.otpModal{
  .content {
    height: 255px;
    background-color: #f8f8f8;
  }
  .content {
    .body {
      padding: 30px 15px;
    }
    .head {
      background-color: white;
    }
  }
  .footer {
    p {
      text-align: center;
      margin-top: 15px;
      /* border-top: 1px solid $linesColor; */
      padding-top: 15px;
    }
  }
  .otpBucket {
    .loader {
      position: absolute;
      margin-top: 2px;
      margin-left: 6px;
      animation: rotate 3s infinite linear;
      span {
        position: absolute;
        left: 0px;
        transform: rotate(130deg);
        opacity: 0.2;
      }
    }
  }
  @media screen and (min-width: 600px) { 
    .content {
      border-radius: 16px;
      .body {
        border-radius: 0 0 16px 16px;
        padding: 30px;
      }
      .head {
        border-radius: 16px 16px 0 0;
      }
    }
  }
}