@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.mobile-menu {
  transition: all 0.2s ease;
  position: fixed;
  z-index: 999;
  background: transparent;
  left: -100%;
  top: 0;
  width: 80%;
  height: 100%;
  z-index: 999;
  &.active {
    left: 0;
    &::before {
      opacity: 1;
      left: 0%;
    }
  }
  .content {
    z-index: 9999;
    padding: 24px 16px;
    background: white;
    width: 100%;
    max-width: 300px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .head {
      justify-content: space-between;
      align-items: center;
      display: flex;
    }
    .body {
      padding: 24px 0px;
      display: flex;
      flex-direction: column;
      height: 100%;
      & > * {
        padding: 10px 0px;
        color: black;
        font-size: 18px;
      }
    }
  }
}
.navbar {
  height: 70px;
  background-color: $navbarColor !important;
  border-bottom: 1px solid $linesColor;

  @media screen and (min-width: 1100px) {
    right: -32px;
  }

  a {
    margin: 0 !important;
  }

  .logo-bucket {
    top: -5px;
    position: relative;
    img {
      position: relative;
      top: 3px;
    }
  }
  .navbar-brand {
    width: 125px;
    color: black;
    font-family: 'Satoshi', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
    font-weight: 500;

    @media screen and (max-width: 330px) {
      width: 107px;
    }

    img {
      width: 100%;
    }
  }
  .navbar-list {
    a {
      transition: color 0.2s ease;
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
    a:hover {
      color: $primaryColor;
    }
    display: inline-block;
    list-style: none;
    margin-bottom: 0px;
    font-size: 12px;
    padding-left: 24px;

    @media screen and (max-width: 700px) {
      padding: 0;
    }

    li {
      display: inline-block;
      padding-right: 16px;

      @media screen and (max-width: 700px) {
        padding: 0;
      }
    }
    &.navbar-list-left {
      position: relative;
      bottom: 1px;
    }
  }
  .menuBtn {
    margin-left: -16px;
    transition: all 0.2s ease;
    padding: 11px 16px;
    margin-right: 5px;
    border-radius: 8px;
    display: inline-block;
    &:hover {
      background-color: #fd8d5d !important;
      color: #ffeae2 !important;
    }
  }
}

@media screen and (max-width: 345px) {
  .navbar {
    .menuBtn {
      font-size: 16px !important;
      margin-right: 0;
    }
  }
}
