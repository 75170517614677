.Profile {
  .flagSelector {
    .itemClassic {
      top: 4px;
      position: relative;
    }
    .inputValue {
      padding: 0px;
    }
    img {
      margin: auto;
    }
  }

  .floatings {
    @media screen and (min-width: 768px) {
      margin-left: 16px;
    }
  }
}
