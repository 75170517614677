.CustomModal {
    position: fixed;
    width: 100%; height: 100%;
    top: 0px; left: 0px;
    z-index: 9999;
    max-width: 0px;
    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    transition: 0.2s ease opacity;
    .blackout {
        transition: 0.2s ease all;
        position: absolute;
        left: 0px; right: 0px; top: 0px; bottom: 0px;
        width: 100%; height: 100%;
        background-color: white;
        opacity: 0;
        filter: blur(20);
    }
    &.active {
        max-width: 100%;
        max-height: 100%;
        opacity: 1;
        .blackout { 
            opacity: 0.4;
        }
    }
    .customModal {
        background: white;
        overflow: auto;
        max-width: 90%;
        @media screen and (min-width: 768px) {
            & { width: 600px; }
        }
        left: 0px; right: 0px;
        margin: auto;
        margin-top: 10%;
        position: absolute;
        border-radius: 10px;
        padding: 16px;
        border: 1px solid $linesColor;
    }
}